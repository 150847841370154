import React, { useState } from 'react';
import { Navbar, Column, Dropdown, Icon } from 'rbx';
import logoImage from '../../assets/images/logo-192-rounded.png';
import "../../styles/header.scss";
import UsersService from '../../services/users';
import { Redirect, useHistory  } from "react-router-dom";
import SidebarMenu from "../components/sidebar-menu"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightFromBracket, faDoorOpen } from '@fortawesome/free-solid-svg-icons'

function Header(props) {

  const { focusedItem, setFocusedItem, setOpenAboutOverlay, hasUnsavedChanges, setHasUnsavedChanges, setNotification } = props

  const [redirectToLogin, setRedirectToLogin] = useState(false)

  let history = useHistory();

  const logOut = async () => {
    await UsersService.logout();
    setRedirectToLogin(true);
  }

  if (redirectToLogin === true)
    return <Redirect to={{ pathname: "/login" }} />

  const name = props.userName

  return (
    <Navbar color="custom-green-dark" className="navbar-logged is-fixed-top">

      <Navbar.Brand>

        <Dropdown>

          <Navbar.Item
            className="is-hidden-touch"
            onClick={e => {
            if (hasUnsavedChanges) {
              setNotification({
                withCancel: true,
                success: false,
                title: "Alterações não salvas",
                text: "Pode haver alterações não salvas. Ir para outra página?",
                cancelCallback: ( () => {
                  setNotification(false)
                }),
                confirmCallback: ( () => {
                  setNotification(false)
                  setHasUnsavedChanges(false)
                })
              })
            } else {
              setOpenAboutOverlay(true)
            }          
          }}>
            <img src={logoImage} alt="logo" />
            <span className="navbar-title px-3 py-1">
              Cliniweb
            </span>
          </Navbar.Item>

          <Dropdown.Trigger className="is-hidden-desktop">
            <Navbar.Item as="div">
              <img src={logoImage} alt="logo" />
              <span className="navbar-title px-3 py-1">
                Cliniweb
              </span>
              <span className="sidebar-menu-dropdown-arrow"/>
            </Navbar.Item>
          </Dropdown.Trigger>

          <Dropdown.Menu>
            <Column.Group className="workspace m-0">
              <Column className="menu-column p-0">
                <SidebarMenu focusedItem={focusedItem} setFocusedItem={setFocusedItem} setObjectToEdit={props.setObjectToEdit} logoImage={props.logoImage} setLogoImage={props.setLogoImage} tradingName={props.tradingName} setTradingName={props.setTradingName} hasUnsavedChanges={hasUnsavedChanges} setHasUnsavedChanges={setHasUnsavedChanges} setNotification={setNotification} />
              </Column>
            </Column.Group>
          </Dropdown.Menu>

        </Dropdown>

        <Navbar.Burger/>

      </Navbar.Brand>

      <Navbar.Menu>
        <Navbar.Segment as="div" className="navbar-item navbar-start" align="start">
        </Navbar.Segment>
        <Navbar.Segment as="div" className="navbar-item navbar-end" align="right">
          <Navbar.Item as="span">
            {name}
          </Navbar.Item>
          <Navbar.Item className="is-underlined"
            onClick={e => {
              if (hasUnsavedChanges) {
                setNotification({
                  withCancel: true,
                  success: false,
                  title: "Alterações não salvas",
                  text: "Pode haver alterações não salvas. Tem certeza que deseja sair?",
                  cancelCallback: ( () => {
                    setNotification(false)
                  }),
                  confirmCallback: ( () => {
                    setNotification(false)
                    setHasUnsavedChanges(false)
                    logOut()
                  })
                })
              } else {
                logOut()
              }
            }}
          >
            <Icon>
              <FontAwesomeIcon icon={faArrowRightFromBracket} />
            </Icon>
            <span>Sair</span>
          </Navbar.Item>
          
        </Navbar.Segment>
      </Navbar.Menu>
      
    </Navbar>
  )
}

export default Header;