import React, { Fragment, useState, useEffect, useRef, createRef } from 'react';
import { Redirect } from "react-router-dom";
import { Column, Section, Card, Title, Dropdown, Icon, Button } from "rbx"
import Components, { methods } from "../methods-and-components/methods-and-components"
import LoadingOverlay from "../../loading-overlay"
import WorkspaceTitle from "../title"
import UsersService from "../../../../services/users"
import EventsService from "../../../../services/events"
import RevenuesOrExpensesService from '../../../../services/revenuesAndExpenses'
import ErrorsService from "../../../../services/errors"
import "../../../../styles/workspace.scss"
import "../../../../styles/loading.scss"
import { DateTime } from "luxon"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarCheck } from '@fortawesome/free-regular-svg-icons'
import FullCalendar from '@fullcalendar/react'
import listPlugin from '@fullcalendar/list' 
import ptbrLocale from '@fullcalendar/core/locales/pt-br'
import interactionPlugin from '@fullcalendar/interaction'
import { faChartLine, faDollarSign, faHouseChimney, faMoneyBillTrendUp, faSliders, faX } from '@fortawesome/free-solid-svg-icons';
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons'
import EditAttendanceOverlay from "../attendance/edit-attendance"
import EditAttendancePackageOverlay from "../attendance-package/edit-attendance-package"

const Home = (props) => {

  const { setNotification, hasUnsavedChanges, setHasUnsavedChanges } = props

  const [loading, setLoading] = useState(false)
  const [redirectToLogin, setRedirectToLogin] = useState(false)

  const [openPayments, setOpenPayments] = useState(null)

  const [events, setEvents] = useState([])
  const [professionals, setProfessionals] = useState([])

  const [eventPreviewInfo, setEventPreviewInfo] = useState(null)
  const [eventPreviewHeight, setEventPreviewHeight] = useState(null)
  const calendarScrollTop = useRef(0)
  const calendarScrollerRef = useRef(null)

  const [eventClickInfo, setEventClickInfo] = useState(null)
  
  const [showWidescreenCashFlow, setShowWidescreenCashFlow] = useState(window.innerWidth <= 1599 ? false : true)

  const [refreshCalendar, setRefreshCalendar] = useState(0)
  const [refreshPendingAttendances, setRefreshPendingAttendances] = useState(0)
  const [refreshCashFlow, setRefreshCashFlow] = useState(0)
  const [refreshPendingFinances, setRefreshPendingFinances] = useState(0)
  const [refreshReport, setRefreshReport] = useState(0)

  const [openRevenueOrExpenseToEdit, setOpenRevenueOrExpenseToEdit] = useState(false)

  const [editAttendance, setEditAttendance] = useState(null)
  const [editAttendancePackage, setEditAttendancePackage] = useState(null)

  const [openConfig, setOpenConfig] = useState(null)
  const [showTodayAttendances, setShowTodayAttendances] = useState(localStorage.getItem("show-today-attendances") == "false" ? false : true)
  const [showPendingAttendances, setShowPendingAttendances] = useState(localStorage.getItem("show-pending-attendances") == "false" ? false : true)
  const [showPendingFinances, setShowPendingFinances] = useState(localStorage.getItem("show-pending-finances") == "false" ? false : true)
  const [showCashFlow, setShowCashFlow] = useState(localStorage.getItem("show-cash-flow") == "false" ? false : true)
  const [showReport, setShowReport] = useState(localStorage.getItem("show-report") == "false" ? false : true)

  const { logoImage } = props

  const sectionRef = useRef()  
  const eventPreviewRef = createRef()

  const user = JSON.parse(localStorage.getItem('user'))

  const getAndSetEvents = async () => {
    setLoading(true)
    await EventsService.getEvents({ period: "today" })
    .then( (response) => {
      let events = response.data
      let eventsToRender = []
      let professionalsToSet = []
      events.map( (event) => {
        if (event.type == "revenue" || event.type == "expense") {
          event.title = methods.stringCut(event.name, 30)
          event.color = event.type == "revenue" ? "#d2f5dc" : "#ffdce4"
          eventsToRender.push(event)
        } else {
          event.color = event.professionalInfo[0].eventsColor
          event.professional = event.professionalInfo[0].name
          event.patient = event.patientInfo[0].name
          event.attendancePackage = event.attendancePackage[0] ? event.attendancePackage[0].name : null
          event.title = `${event.patient.split(" ")[0]} [${event.professional.split(" ")[0].toUpperCase()}]`
          if (event.patientInfo[0].phoneNumber) {
            event.patientPhoneNumber = `${event.patientInfo[0].areaCode.lenght > 0 ? `(${event.patientInfo[0].areaCode}) ` : ""}${methods.maskAndSetPhoneNumber(event.patientInfo[0].phoneNumber)}`
          }
          if (!professionalsToSet.some( (professional) => {
            return professional.id == event.professionalId
          })) {
            professionalsToSet.push({
              name: event.professionalInfo[0].name,
              id: event.professionalId,
              eventsColor: event.professionalInfo[0].eventsColor,
              show: true,
            })
          }
          eventsToRender.push(event)
        }
        setProfessionals(professionalsToSet)
      })
      setEvents(eventsToRender)
      setLoading(false)
    })
    .catch(async function (error) {
      try {
        if (error && error.response && error.response.status == 401) {
          setLoading(false)
          setNotification({
            success: false,
            text: "Erro de autenticação. Você será redirecionado para a tela de login",
            callback: ( () => {
              setNotification(false)
              UsersService.logout()
              setRedirectToLogin(true)
            })
          })
        } else {
          setLoading(true)
          await ErrorsService.newError({
            origin: "Home - getAndSetEvents",
            message: error && error.response && error.response.data || error && error.message || "não especificado",
            stack: error && error.stack || ""
          })
          .then(response => {
            setLoading(false)
            setNotification({
              success: false,
              text:
                error && error.response && error.response.data
                ||
                <Fragment>
                  <p className="mb-3">Erro ao processar a solicitação.</p>
                  {error && error.message &&
                    <Fragment>
                      <p className="mb-3 has-text-weight-normal">{"Mensagem: " + error.message}</p>
                      <p className="has-text-weight-normal">{"Stack: " + (error && error.stack || "")}</p>
                    </Fragment>
                  }
                </Fragment>
              ,
              callback: ( () => {
                setNotification(false)
              })
            })
          })
        }
      } catch {
        setLoading(false)
        setNotification({
          success: false,
          text: "Não foi possível atualizar as informações. Tente novamente",
          callback: ( () => {
            setNotification(false)
          })
        })
      }
    })
  }

  const renderEventContent = (eventInfo) => {
    let paid = 0
    if (eventInfo.event._def.extendedProps.payments) {
      eventInfo.event._def.extendedProps.payments.map( (payment) => {
        if (payment) {
          paid += payment.value
        }
      })
    }
    if (eventInfo.event._def.extendedProps.attendancePackageId) {
      paid = "Pacote de atendimento"
    } else if (eventInfo.event._def.extendedProps.price == 0 || !eventInfo.event._def.extendedProps.price) {
      paid = "Sem cobrança"
    } else if (eventInfo.event._def.extendedProps.price > paid) {
      paid = "Pendente"
    } else if (eventInfo.event._def.extendedProps.price <= paid) {
      paid = "Pago"
    } 
    const eventType = eventInfo.event._def.extendedProps.type
    const paidElement =
      (eventType == "revenue" || eventType == "expense") &&
      <span className={paid == "Sem cobrança" ? "has-background-grey has-text-white event-icon" : paid == "Pago" ? "has-background-success has-text-white event-icon" : "has-background-danger has-text-white event-icon"} >
        {paid}
      </span>
      ||
      <span className={paid == "Pacote de atendimento" ? "has-background-primary has-text-white ml-2 event-icon" : paid == "Sem cobrança" ? "has-background-grey has-text-white ml-2 event-icon" : paid == "Pago" ? "has-background-success has-text-white ml-2 event-icon" : "has-background-danger has-text-white ml-2 event-icon"} >{paid == "Pacote de atendimento" ? "▣" : "$"}</span>
    const eventStatus = eventInfo.event._def.extendedProps.status
    const statusElement =
      (eventType == "revenue" || eventType == "expense") &&
      <span className="has-text-dark">{eventType == "revenue" ? "Receita: " : "Despesa: "}</span>
      ||
      <span className={eventStatus == "Atendido" ? "has-background-success has-text-white ml-2 event-icon" : (eventStatus == "Aguard. atendimento" ? "has-background-info has-text-white ml-2 event-icon" : "has-background-danger has-text-white ml-2 event-icon") } >
        {eventStatus == "Atendido" ? "✓" : (eventStatus == "Aguard. atendimento" ? "⧗" : "✕")}
      </span>

    return (
      <Fragment>
        {(eventType == "revenue" || eventType == "expense") &&
        <span className="has-text-dark">
          {statusElement}
          <a>{eventInfo.event._def.title}</a>
          <span className='ml-2'>{paidElement}</span>
        </span>
        ||
        <span>
          <a>{eventInfo.event._def.title}</a>
          {statusElement}
          {paidElement}
        </span>
        }
      </Fragment>
    )
  }

  const handleEventMouseEnter = (mouseEnterInfo) => {
    const isRevenueOrExpense = (mouseEnterInfo.event._def.extendedProps.type == "revenue" || mouseEnterInfo.event._def.extendedProps.type == "expense")
    calendarScrollerRef.current = document.getElementsByClassName('fc-scroller')[1]
    calendarScrollerRef.current ? calendarScrollTop.current = calendarScrollerRef.current.scrollTop : null
    if (!eventClickInfo) {
      let element = mouseEnterInfo.jsEvent.target
      if (/fc-event-title\sfc-sticky|event-icon/.test(element.classList.value)) {
        element = element.parentElement.parentElement.parentElement.parentElement
      } else if (/fc-event-title-container|fc-event-time/.test(element.classList.value)) {
        element = element.parentElement.parentElement.parentElement
      } else if (/fc-event-main-frame|fc-event-resizer/.test(element.classList.value)) {
        element = element.parentElement.parentElement
      } else if (/fc-event-main/.test(element.classList.value)) {
        element = element.parentElement
      }
      let rect = element.getBoundingClientRect()
      let left = window.innerWidth - rect.left > 260 ? rect.left : null
      let right = left == null ? window.innerWidth - rect.right : null
      let start = null
      let end = null
      let price = mouseEnterInfo.event._def.extendedProps.price ? mouseEnterInfo.event._def.extendedProps.price : 0
      price = methods.numberToPrice(price)
      let paid = 0
      if (mouseEnterInfo.event._def.extendedProps.payments) {
        mouseEnterInfo.event._def.extendedProps.payments.map( (payment) => {
          if (payment) {
            paid += payment.value
          }
        })
      }
      let debt = price != 0 ? mouseEnterInfo.event._def.extendedProps.price - paid : 0
      debt = methods.numberToPrice(debt.toFixed(2))
      paid = methods.numberToPrice(paid.toFixed(2))
      setEventPreviewInfo({
        isRevenueOrExpense: isRevenueOrExpense,
        name: mouseEnterInfo.event._def.extendedProps.name,
        attendancePackage: mouseEnterInfo.event._def.extendedProps.attendancePackage,
        attendancePackageId: mouseEnterInfo.event._def.extendedProps.attendancePackageId,
        top: rect.top,
        left: left,
        right: right,
        start: start,
        end: end,
        professional: mouseEnterInfo.event._def.extendedProps.professional,
        patient: mouseEnterInfo.event._def.extendedProps.patient,
        description: mouseEnterInfo.event._def.extendedProps.description,
        status: mouseEnterInfo.event._def.extendedProps.status,
        price: price,
        paid: paid,
        debt: debt
      })
    }
    calendarScrollerRef.current ? calendarScrollerRef.current.scrollTop = calendarScrollTop.current : null
  }
  
  const handleEventMouseLeave = (mouseLeaveInfo) => {
    calendarScrollerRef.current = document.getElementsByClassName('fc-scroller')[1]
    calendarScrollerRef.current ? calendarScrollTop.current = calendarScrollerRef.current.scrollTop : null
    setEventPreviewInfo(null)
    calendarScrollerRef.current ? calendarScrollerRef.current.scrollTop = calendarScrollTop.current : null
  }

  const handleEventClick = (eventClickInfo) => {
    calendarScrollerRef.current = document.getElementsByClassName('fc-scroller')[1]
    calendarScrollerRef.current ? calendarScrollTop.current = calendarScrollerRef.current.scrollTop : null
    setEventPreviewInfo(null)
    let price = eventClickInfo.event._def.extendedProps.price ? eventClickInfo.event._def.extendedProps.price : 0
    price = methods.numberToPrice(price)
    let paid = 0
    if (eventClickInfo.event._def.extendedProps.payments) {
      eventClickInfo.event._def.extendedProps.payments.map( (payment) => {
        if (payment) {
          paid += payment.value
        }
      })
    }
    let debt = price != 0 ? eventClickInfo.event._def.extendedProps.price - paid : 0
    if (eventClickInfo.event._def.extendedProps.type == "revenue" || eventClickInfo.event._def.extendedProps.type == "expense") {
      setOpenRevenueOrExpenseToEdit({
        ...eventClickInfo.event._def.extendedProps,
        paid: paid,
        debt: debt,
      })
    } else {
      debt = methods.numberToPrice(debt.toFixed(2))
      paid = methods.numberToPrice(paid.toFixed(2))
      setEventClickInfo({
        attendancePackage: eventClickInfo.event._def.extendedProps.attendancePackage,
        attendancePackageId: eventClickInfo.event._def.extendedProps.attendancePackageId,
        id: eventClickInfo.event._def.extendedProps._id,
        recurrenceId: eventClickInfo.event._def.extendedProps.recurrenceId,
        recurrenceWeekdays: eventClickInfo.event._def.extendedProps.recurrenceWeekdays,
        date: eventClickInfo.event.start,
        start: eventClickInfo.event.start,
        end: eventClickInfo.event.end,
        patient: eventClickInfo.event._def.extendedProps.patient,
        patientId: eventClickInfo.event._def.extendedProps.patientId,
        patientPhone: eventClickInfo.event._def.extendedProps.patientPhone,
        professional: eventClickInfo.event._def.extendedProps.professional,
        professionalId: eventClickInfo.event._def.extendedProps.professionalId,
        description: eventClickInfo.event._def.extendedProps.description,
        status: eventClickInfo.event._def.extendedProps.status,
        payments: eventClickInfo.event._def.extendedProps.payments,
        price: price,
        paid: paid,
        debt: debt,
        hasRegisteredAttendance: eventClickInfo.event._def.extendedProps.attendanceDateTime ? true : false,
      })
    }
    calendarScrollerRef.current ? calendarScrollerRef.current.scrollTop = calendarScrollTop.current : null
  }

  useEffect( () => {
    if (showTodayAttendances) getAndSetEvents()

    window.oncontextmenu = (event) => {
      event.preventDefault()
      event.stopPropagation()
      return false
    }
    sectionRef.current && sectionRef.current.scrollIntoView()

    const toggleShowWidescreenCashFlow = () => {
      if (window.innerWidth < 1650) {
        setShowWidescreenCashFlow(false)
      } else if (window.innerWidth >= 1650) {
        setShowWidescreenCashFlow(true)
      }
    }
    window.addEventListener("resize", toggleShowWidescreenCashFlow)
    return () => {window.removeEventListener("resize", toggleShowWidescreenCashFlow)}
  }, [])

  useEffect( () => {
    if (eventPreviewRef.current) {
      setEventPreviewHeight(eventPreviewRef.current.clientHeight)
    }
  }, [eventPreviewInfo])

  useEffect(() => {
    refreshCalendar != 0 && getAndSetEvents()
  }, [refreshCalendar])

  useEffect(() => {
    showTodayAttendances && getAndSetEvents()
  }, [showTodayAttendances])

  if (redirectToLogin) {
    return <Redirect to="/"/>
  }

  return (
    <Section ref={sectionRef} className="workspace-section">
      {loading && <LoadingOverlay/>}
      {eventPreviewInfo &&
        <Components.EventPreview
          eventPreviewRef={eventPreviewRef}
          eventPreviewInfo={eventPreviewInfo}
          eventPreviewHeight={eventPreviewHeight}
        />
      }

      <WorkspaceTitle>
        <Column.Group vcentered breakpoint="mobile" className="m-0" >
          <Column narrow className="py-0 px-0" >
            <span className="pr-3"><FontAwesomeIcon icon={faHouseChimney} /></span>
          </Column>
          <Column narrow className="py-0 px-0" >
            <span className="mr-5">Início</span>
          </Column>
          <Column narrow className="py-0 pl-1 pr-0" >
            <Components.ToogleButton
              color="primary"
              icon={faSliders}
              setOpen={setOpenConfig}
            >
              Personalizar
            </Components.ToogleButton>
          </Column>
        </Column.Group>
      </WorkspaceTitle>

      <Column.Group multiline breakpoint="desktop">

        {showTodayAttendances &&
          <Column className={`is-12-desktop is-12-widescreen ${showPendingAttendances ? "is-6-fullhd" : "is-12-fullhd"} ` + ((user.isCompanyAdm || user.isSystemAdm) ? (showPendingAttendances ? "screen1650-33" : "screen1650-66") : "")}>
            <Card>

              <Section className="py-0 px-4">

                <Column.Group vcentered className="m-0 pt-3 calendar-title" breakpoint="mobile">
                  <Column narrow className="p-0">
                    <span className="pr-3 calendar-title"><FontAwesomeIcon icon={faCalendarCheck} /></span>
                  </Column>
                  <Column className="p-0">
                    <Title className="calendar-title mb-0">
                      Agendamentos de hoje
                    </Title>
                  </Column>
                </Column.Group>

                {eventClickInfo &&
                  <Components.EventClickOverlay
                    isCalendarEvent={true}
                    refreshCalendar={refreshCalendar}
                    setRefreshCalendar={setRefreshCalendar}
                    refreshPendingAttendances={refreshPendingAttendances}
                    setRefreshPendingAttendances={setRefreshPendingAttendances}
                    refreshCashFlow={refreshCashFlow}
                    setRefreshCashFlow={setRefreshCashFlow}
                    refreshPendingFinances={refreshPendingFinances}
                    setRefreshPendingFinances={setRefreshPendingFinances}
                    refreshReport={refreshReport}
                    setRefreshReport={setRefreshReport}
                    eventClickInfo={eventClickInfo}
                    setEventClickInfo={setEventClickInfo}
                    setOpenPayments={setOpenPayments}
                    setEditAttendance={setEditAttendance}
                    setEditAttendancePackage={setEditAttendancePackage}
                    setFocusedItem={props.setFocusedItem}
                    setNotification={setNotification}
                    setLoading={setLoading}
                    getAndSetEvents={getAndSetEvents}
                    setRedirectToLogin={setRedirectToLogin}
                    setObjectToEdit={props.setObjectToEdit}
                  />
                }

                {openRevenueOrExpenseToEdit &&
                  <Components.RevenueOrExpenseToEditOverlay
                    refreshCalendar={null}
                    setRefreshCalendar={null}
                    refreshPendingAttendances={null}
                    setRefreshPendingAttendances={null}
                    refreshCashFlow={null}
                    setRefreshCashFlow={null}
                    refreshPendingFinances={null}
                    setRefreshPendingFinances={null}
                    refreshReport={null}
                    setRefreshReport={null}
                    openRevenueOrExpenseToEdit={openRevenueOrExpenseToEdit}
                    setOpenRevenueOrExpenseToEdit={setOpenRevenueOrExpenseToEdit}
                    getAndSetEvents={getAndSetEvents}
                    setEditAttendance={null}
                    setEditAttendancePackage={null}
                    setFocusedItem={props.setFocusedItem}
                    setObjectToEdit={props.setObjectToEdit}
                    setNotification={setNotification}
                    setHasUnsavedChanges={setHasUnsavedChanges}
                    setLoading={setLoading}
                    setRedirectToLogin={setRedirectToLogin}
                  />
                }

                {openPayments &&
                  <Components.PaymentsOverlay
                    isCalendarPayment={true}
                    refreshCalendar={refreshCalendar}
                    setRefreshCalendar={setRefreshCalendar}
                    refreshPendingAttendances={refreshPendingAttendances}
                    setRefreshPendingAttendances={setRefreshPendingAttendances}
                    refreshCashFlow={refreshCashFlow}
                    setRefreshCashFlow={setRefreshCashFlow}
                    refreshPendingFinances={refreshPendingFinances}
                    setRefreshPendingFinances={setRefreshPendingFinances}
                    refreshReport={refreshReport}
                    setRefreshReport={setRefreshReport}
                    eventClickInfo={eventClickInfo}
                    setOpenPayments={setOpenPayments}
                    setEventClickInfo={setEventClickInfo}
                    getAndSetEvents={getAndSetEvents}
                    setNotification={setNotification}
                    setLoading={setLoading}
                    setRedirectToLogin={setRedirectToLogin}
                  />
                }

              </Section>

              <Section className="py-5 px-5 home-card-inner-section thin-scroller" style={{ whiteSpace: "nowrap", overflowY: "auto" }}>

                <FullCalendar
                  plugins={[ listPlugin, interactionPlugin ]}
                  locale={ptbrLocale}
                  height="auto"
                  expandRows={true}
                  headerToolbar={false}
                  listDayFormat={{
                    month: 'long',
                    year: 'numeric',
                    day: 'numeric',
                    weekday: 'long',
                  }}
                  slotLabelFormat={
                    {
                      hour: 'numeric',
                      minute: '2-digit',
                      omitZeroMinute: false,
                      meridiem: 'short'
                    }
                  }
                  stickyHeaderDates={true}
                  initialView="listDay"
                  allDayText={"Receitas/ despesas"}

                  editable={true}

                  events={events}

                  eventContent={renderEventContent}

                  eventMouseEnter={handleEventMouseEnter}
                  eventMouseLeave={handleEventMouseLeave}
                  eventClick={handleEventClick}

                  noEventsContent={
                    <Fragment>
                      <div className="has-text-success has-text-centered p-3">
                        <FontAwesomeIcon size="4x" icon={faCircleCheck} />
                      </div>
                      <Title size={5} className="has-text-centered has-text-success" >
                        Não há agendamentos para hoje
                      </Title>
                    </Fragment>
                  }

                />

              </Section>

            </Card>
          </Column>
        }
        

        {showPendingAttendances &&
          <Column className={`is-12-desktop is-12-widescreen ${showTodayAttendances ? "is-6-fullhd" : "is-12-fullhd"} ` + ((user.isCompanyAdm || user.isSystemAdm) ? (showTodayAttendances ? "screen1650-33" : "screen1650-66") : "")}>
            <Card >

              <Section className="py-0 px-4">

                <Column.Group vcentered className="m-0 pt-3 calendar-title" breakpoint="mobile">
                  <Column narrow className="p-0">
                    <span className="pr-3 calendar-title has-text-danger"><FontAwesomeIcon icon={faX} /></span>
                  </Column>
                  <Column className="p-0">
                    <Title className="calendar-title mb-0">
                      Atendimentos atrasados
                    </Title>
                  </Column>
                </Column.Group>

              </Section>

              <Section className="py-5 px-5 thin-scroller home-card-inner-section" style={{ whiteSpace: "nowrap", overflowY: "auto" }}>
                <Components.GetPendingAttendancesResults
                  refreshCalendar={refreshCalendar}
                  setRefreshCalendar={setRefreshCalendar}
                  refreshPendingAttendances={refreshPendingAttendances}
                  setRefreshPendingAttendances={setRefreshPendingAttendances}
                  refreshCashFlow={refreshCashFlow}
                  setRefreshCashFlow={setRefreshCashFlow}
                  refreshPendingFinances={refreshPendingFinances}
                  setRefreshPendingFinances={setRefreshPendingFinances}
                  refreshReport={refreshReport}
                  setRefreshReport={setRefreshReport}
                  setEventPreviewInfo={setEventPreviewInfo}
                  setEditAttendance={setEditAttendance}
                  setEditAttendancePackage={setEditAttendancePackage}
                  setFocusedItem={props.setFocusedItem}
                  setObjectToEdit={props.setObjectToEdit}
                  setNotification={setNotification}
                  hasUnsavedChanges={hasUnsavedChanges}
                  setHasUnsavedChanges={setHasUnsavedChanges}yjhb 
                  setLoading={setLoading}
                  setRedirectToLogin={setRedirectToLogin}
                />

              </Section>

            </Card>
          </Column>
        }

        {showCashFlow &&
          <Fragment>
            {(showWidescreenCashFlow && user.isCompanyAdm) &&
              <Column className={`is-12-desktop is-12-widescreen ${showReport ? "is-6-fullhd" : "is-12-fullhd"} ` + ((user.isCompanyAdm || user.isSystemAdm) ? (showReport ? "screen1650-33" : "screen1650-66") : "")}>
                <Card>

                  <Section className="py-0 px-4">

                    <Column.Group vcentered className="m-0 pt-3 calendar-title" breakpoint="mobile">
                      <Column narrow className="p-0">
                        <span className="pr-3 calendar-title has-text-success"><FontAwesomeIcon icon={faMoneyBillTrendUp} /></span>
                      </Column>
                      <Column className="p-0">
                        <Title className="calendar-title mb-0">
                          Fluxo de caixa ({DateTime.fromJSDate(new Date()).toFormat('LLL yyyy')})
                        </Title>
                      </Column>
                    </Column.Group>

                  </Section>

                  <Section className="py-5 px-5 thin-scroller home-card-inner-section is-clipped" style={{ whiteSpace: "nowrap", overflowY: "auto" }}>
                    <Components.CashFlow
                      refreshCalendar={refreshCalendar}
                      setRefreshCalendar={setRefreshCalendar}
                      refreshPendingAttendances={refreshPendingAttendances}
                      setRefreshPendingAttendances={setRefreshPendingAttendances}
                      refreshCashFlow={refreshCashFlow}
                      setRefreshCashFlow={setRefreshCashFlow}
                      refreshPendingFinances={refreshPendingFinances}
                      setRefreshPendingFinances={setRefreshPendingFinances}
                      refreshReport={refreshReport}
                      setRefreshReport={setRefreshReport}
                      isHomeCashFlow={true}
                      setOpenRevenueOrExpenseToEdit={null}
                      setObjectToEdit={props.setObjectToEdit}
                      setFocusedItem={props.setFocusedItem}
                      refreshRevenuesAndExpenses={null}
                      setRefreshRevenuesAndExpenses={null}
                      setNotification={setNotification}
                      setLoading={setLoading}
                      setRedirectToLogin={setRedirectToLogin}
                    />
                  </Section>

                </Card>
              </Column>
            }
          </Fragment>
        }
        
        {showPendingFinances &&
          <Column className={"is-12-desktop is-12-widescreen is-12-fullhd " + ((user.isCompanyAdm || user.isSystemAdm) ? "screen1650-66" : "")}>
            <Card>

              <Section className="py-0 px-4">

                <Column.Group vcentered className="m-0 pt-3 calendar-title" breakpoint="mobile">
                  <Column narrow className="p-0">
                    <span className="pr-3 calendar-title has-text-danger"><FontAwesomeIcon icon={faDollarSign} /></span>
                  </Column>
                  <Column className="p-0">
                    <Title className="calendar-title mb-0">
                      Pendências financeiras (vencidas ou a vencer em 7 dias)
                    </Title>
                  </Column>
                </Column.Group>

              </Section>

              <Section className="py-5 px-5 thin-scroller home-card-inner-section" style={{ whiteSpace: "nowrap", overflowY: "auto" }}>
                <Components.GetPendingFinancesResults
                  refreshCalendar={refreshCalendar}
                  setRefreshCalendar={setRefreshCalendar}
                  refreshPendingAttendances={refreshPendingAttendances}
                  setRefreshPendingAttendances={setRefreshPendingAttendances}
                  refreshCashFlow={refreshCashFlow}
                  setRefreshCashFlow={setRefreshCashFlow}
                  refreshPendingFinances={refreshPendingFinances}
                  setRefreshPendingFinances={setRefreshPendingFinances}
                  refreshReport={refreshReport}
                  setRefreshReport={setRefreshReport}
                  setEventPreviewInfo={setEventPreviewInfo}
                  setEditAttendance={setEditAttendance}
                  setEditAttendancePackage={setEditAttendancePackage}
                  setFocusedItem={props.setFocusedItem}
                  setObjectToEdit={props.setObjectToEdit}
                  setNotification={setNotification}
                  setLoading={setLoading}
                  setRedirectToLogin={setRedirectToLogin}
                />
              </Section>

            </Card>
          </Column>
        }

        {openConfig &&
          <Components.HomeConfigOverlay
            setOpenConfig={setOpenConfig}
            showTodayAttendances={showTodayAttendances}
            setShowTodayAttendances={setShowTodayAttendances}
            showPendingAttendances={showPendingAttendances}
            setShowPendingAttendances={setShowPendingAttendances}
            showPendingFinances={showPendingFinances}
            setShowPendingFinances={setShowPendingFinances}
            showCashFlow={showCashFlow}
            setShowCashFlow={setShowCashFlow}
            showReport={showReport}
            setShowReport={setShowReport}
            setNotification={setNotification}
            setLoading={setLoading}
            setRedirectToLogin={setRedirectToLogin}
          />
        }

        {editAttendance &&
          <EditAttendanceOverlay
            editAttendance={editAttendance}
            setEditAttendance={setEditAttendance}
            getAttendances={editAttendance.getAttendances || getAndSetEvents}
            focusedItem={"home"}
            setFocusedItem={props.setFocusedItem}
            setObjectToEdit={props.setObjectToEdit}
            setNotification={setNotification}
            hasUnsavedChanges={hasUnsavedChanges}
            setHasUnsavedChanges={setHasUnsavedChanges}
            logoImage={logoImage}
          />
        }

        {editAttendancePackage &&
          <EditAttendancePackageOverlay
            editAttendancePackage={editAttendancePackage}
            setEditAttendancePackage={setEditAttendancePackage}
            getAndSetEvents={getAndSetEvents}
            getAttendancePackages={null}
            setNotification={setNotification}
            hasUnsavedChanges={hasUnsavedChanges}
            setHasUnsavedChanges={setHasUnsavedChanges}
          />
        }

        {showCashFlow &&
          <Fragment>
            {(!showWidescreenCashFlow && user.isCompanyAdm) &&
              <Column className={`is-12-desktop is-12-widescreen ${showReport ? "is-6-fullhd" : "is-12-fullhd"} ` + ((user.isCompanyAdm || user.isSystemAdm) ? (showReport ? "screen1650-33" : "screen1650-66") : "")}>
                <Card>

                  <Section className="py-0 px-4">

                    <Column.Group vcentered className="m-0 pt-3 calendar-title" breakpoint="mobile">
                      <Column narrow className="p-0">
                        <span className="pr-3 calendar-title has-text-success"><FontAwesomeIcon icon={faMoneyBillTrendUp} /></span>
                      </Column>
                      <Column className="p-0">
                        <Title className="calendar-title mb-0">
                          Fluxo de caixa ({DateTime.fromJSDate(new Date()).toFormat('LLL yyyy')})
                        </Title>
                      </Column>
                    </Column.Group>

                  </Section>

                  <Section className="py-5 px-5 thin-scroller home-card-inner-section is-clipped" style={{ whiteSpace: "nowrap", overflowY: "auto" }}>
                    <Components.CashFlow
                      refreshCalendar={refreshCalendar}
                      setRefreshCalendar={setRefreshCalendar}
                      refreshPendingAttendances={refreshPendingAttendances}
                      setRefreshPendingAttendances={setRefreshPendingAttendances}
                      refreshCashFlow={refreshCashFlow}
                      setRefreshCashFlow={setRefreshCashFlow}
                      refreshPendingFinances={refreshPendingFinances}
                      setRefreshPendingFinances={setRefreshPendingFinances}
                      refreshReport={refreshReport}
                      setRefreshReport={setRefreshReport}
                      isHomeCashFlow={true}
                      setOpenRevenueOrExpenseToEdit={null}
                      setObjectToEdit={props.setObjectToEdit}
                      setFocusedItem={props.setFocusedItem}
                      refreshRevenuesAndExpenses={null}
                      setRefreshRevenuesAndExpenses={null}
                      setNotification={setNotification}
                      setLoading={setLoading}
                      setRedirectToLogin={setRedirectToLogin}
                    />
                  </Section>

                </Card>
              </Column>
            }
          </Fragment>
        }
        
        {showReport &&
          <Fragment>
            {user.isCompanyAdm &&
              <Column className={`is-12-desktop is-12-widescreen ${showCashFlow ? "is-6-fullhd" : "is-12-fullhd"} ` + ((user.isCompanyAdm || user.isSystemAdm) ? (showCashFlow ? "screen1650-33" : "screen1650-66") : "")}>
                <Card>

                  <Section className="py-0 px-4">

                    <Column.Group vcentered className="m-0 pt-3 calendar-title" breakpoint="mobile">
                      <Column narrow className="p-0">
                        <span className="pr-3 calendar-title"><FontAwesomeIcon icon={faChartLine} /></span>
                      </Column>
                      <Column className="p-0">
                        <Title className="calendar-title mb-0">
                          Relatório ({DateTime.fromJSDate(new Date()).toFormat('LLL yyyy')})
                        </Title>
                      </Column>
                    </Column.Group>

                  </Section>

                  <Section className="py-5 px-5 thin-scroller home-card-inner-section is-clipped" style={{ whiteSpace: "nowrap", overflowY: "auto" }}>
                    <Components.Reports
                      isHomeReport={true}
                      refreshCalendar={refreshCalendar}
                      setRefreshCalendar={setRefreshCalendar}
                      refreshPendingAttendances={refreshPendingAttendances}
                      setRefreshPendingAttendances={setRefreshPendingAttendances}
                      refreshCashFlow={refreshCashFlow}
                      setRefreshCashFlow={setRefreshCashFlow}
                      refreshPendingFinances={refreshPendingFinances}
                      setRefreshPendingFinances={setRefreshPendingFinances}
                      refreshReport={refreshReport}
                      setRefreshReport={setRefreshReport}
                      setObjectToEdit={props.setObjectToEdit}
                      setFocusedItem={props.setFocusedItem}
                      setNotification={setNotification}
                      setLoading={setLoading}
                      setRedirectToLogin={setRedirectToLogin}
                    />
                  </Section>

                </Card>
              </Column>
            }
          </Fragment>
        }
        

      </Column.Group>

    </Section>
  )
}

export default Home;