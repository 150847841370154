import React from "react"
import "./App.scss"
import Routes from "./routes"

function App () {
  return (
    <Routes/>
  )
}

export default App;