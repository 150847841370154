const ufList = ['', 'AC','AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MS', 'MT','MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO',]
const slotTimesList = ["00:00", "01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00", "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00", "24:00" ]
const slotDurationList = ["00:10", "00:15", "00:20", "00:30", "01:00", "02:00"]
const eventDurationList = ["00:15", "00:30", "00:45", "01:00", "01:30", "02:00"]
const monthsList = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"]
const abbMonthsList = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"]
const quantityTypes = [
  {
    name: "Qualidade",
    units: [],
    values: [
      "Excelente",
      "Bom",
      "Regular",
      "Insuficiente",
      "Ruim",
    ],
  },
  {
    name: "Dor",
    units: [],
    values: [
      "Alta",
      "Média",
      "Baixa",
      "Inexistente",
    ],
  },
  {
    name: "Sensibilidade",
    units: [],
    values: [
      "Alta",
      "Média",
      "Baixa",
      "Inexistente",
    ],
  },
  {
    name: "Quantidade",
    units: [],
    values: [],
  },
  {
    name: "Frequência",
    units: [
      "por segundo (Hz)",
      "por minuto",
      "por hora",
      "por dia",
      "por semana",
      "por mês",
      "por ano",
    ],
    values: [],
  },
  {
    name: "Força",
    units: [
      "N (newtons)",
      "KN (quilonewtons)",
      "kgf (quilogramas-força)",
      "lbf (libras-força)"
    ],
    values: [],
  },
  {
    name: "Ângulo",
    units: [
      "° (graus)",
      "rad (radianos)",
    ],
    values: [],
  },
  {
    name: "Comprimento",
    units: [
      "mm (milímetros)",
      "cm (centímetros)",
      "m (metros)",
      "km (quilômetros)",
    ],
    values: [],
  },
  {
    name: "Tempo",
    units: [
      "ms (milisegundos)",
      "s (segundos)",
      "min (minutos)",
      "h (horas)",
      "dias",
      "meses",
      "anos,"
    ],
    values: [],
  },
  {
    name: "Massa",
    units: [
      "mg (miligramas)",
      "g (gramas)",
      "kg (quilogramas)",
    ],
    values: [],
  },
  {
    name: "IMC (índice de massa corporal)",
    units: [
      "kg/m²",
    ],
    values: [],
  },
  {
    name: "Velocidade",
    units: [
      "cm/s (centímetros por segundo)",
      "m/s (metros por segundo)",
      "km/h"
    ],
    values: [],
  },
  {
    name: "Outro",
    units: [],
    values: [],
  },
]

export { ufList, slotTimesList, slotDurationList, eventDurationList, monthsList, abbMonthsList, quantityTypes }