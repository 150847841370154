import Api from "./api"

const UsersService = {

  newUser: async (params) => {
    const response = await Api.post("/users/newUser", params,
      {
        headers: {
          "auth-token": localStorage.getItem("auth-token"),
          "keep-logged": localStorage.getItem("keep-logged"),
        }
      }
    )
    localStorage.setItem("auth-token", response.headers["auth-token"])
  },

  autoLogin: async (params) => {
    const response = await Api.get("/auth/autoLogin", 
      {
        headers: {
          "auth-token": localStorage.getItem("auth-token"),
          "keep-logged": localStorage.getItem("keep-logged") 
        }
      }
    )
    localStorage.setItem("auth-token", response.headers["auth-token"])
    localStorage.setItem("user", JSON.stringify(response.data.user))
    if (response.data.company) {
      localStorage.setItem("company", JSON.stringify(response.data.company))
    }
  },

  refreshIndexHtml: async (params) => {
    const response = await Api.get("/")
    return response
  },

  login: async (params) => {
    await Api.post("/auth/login", params)
    .then(response => {
      localStorage.setItem("user", JSON.stringify(response.data.user))
      if (response.data.company) {
        localStorage.setItem("company", JSON.stringify(response.data.company))
      }
      localStorage.setItem("auth-token", response.data.token)
      localStorage.setItem("keep-logged", params.keepLogged)
    })
  },

  logout: () => {
    localStorage.removeItem("user", null)
    localStorage.removeItem("auth-token", null)
    localStorage.removeItem("keepLogged", null)
  },

  forgotPassword: async (params) => {
    await Api.post("/auth/forgotPassword", params)
  },

  resetPassword: async (params) => {
    await Api.post("/auth/resetPassword", params)
  },

  changePassword: async (params) => {
    await Api.put("/users/changePassword", params,
      {
        headers: {
          "auth-token": localStorage.getItem("auth-token"),
          "keep-logged": localStorage.getItem("keep-logged") 
        }
      }
    )
  },

  editMyInfo: async (params) => {
    let response = await Api.put("/users/editMyInfo", params,
      {
        headers: {
          "auth-token": localStorage.getItem("auth-token"),
          "keep-logged": localStorage.getItem("keep-logged"),
        }
      }
    )
    localStorage.setItem("user", JSON.stringify(response.data))
    localStorage.setItem("auth-token", response.headers["auth-token"])
  },

  editUser: async (params) => {
    let response = await Api.put("/users/editUser", params,
      {
        headers: {
          "auth-token": localStorage.getItem("auth-token"),
          "keep-logged": localStorage.getItem("keep-logged"),
        }
      }
    )
    localStorage.setItem("auth-token", response.headers["auth-token"])
  },

  editProfessionalsEventsColors: async (params) => {
    let response = await Api.put("/users/editProfessionalsEventsColors", params,
      {
        headers: {
          "auth-token": localStorage.getItem("auth-token"),
          "keep-logged": localStorage.getItem("keep-logged"),
        }
      }
    )
    localStorage.setItem("auth-token", response.headers["auth-token"])
  },

  search: async (params) => {
    let response = await Api.post("/users/search", params,
    {
      headers: {
      "auth-token": localStorage.getItem("auth-token"),
      "keep-logged": localStorage.getItem("keep-logged") 
      }
    })
    localStorage.setItem("auth-token", response.headers["auth-token"])
    return response
  },

  searchByName: async (params) => {
    let response = await Api.post("/users/searchByName", params,
    {
      headers: {
      "auth-token": localStorage.getItem("auth-token"),
      "keep-logged": localStorage.getItem("keep-logged") 
      }
    })
    localStorage.setItem("auth-token", response.headers["auth-token"])
    return response
  },

  getUsers: async (params) => {
    let response = await Api.post("/users/getUsers", params,
    {
      headers: {
      "auth-token": localStorage.getItem("auth-token"),
      "keep-logged": localStorage.getItem("keep-logged") 
      }
    })
    localStorage.setItem("auth-token", response.headers["auth-token"])
    return response
  },

}

export default UsersService