import React, { Fragment, useState, useEffect } from 'react';
import { Redirect } from "react-router-dom";
import { Section } from "rbx"
import Components, { methods } from "../methods-and-components/methods-and-components"
import LoadingOverlay from "../../loading-overlay"
import "../../../../styles/workspace.scss"
import "../../../../styles/loading.scss"
import { faEdit } from '@fortawesome/free-solid-svg-icons'


const EditAttendancePackage = (props) => {

  const { editAttendancePackage, setEditAttendancePackage, getAttendancePackages, getAndSetEvents, getAttendances, hasUnsavedChanges, setHasUnsavedChanges, setNotification } = props

  const [patientId, setPatientId] = useState(editAttendancePackage && editAttendancePackage.patientId)
  const [patientIdError, setPatientIdError] = useState(null)
  const [name, setName] = useState(editAttendancePackage && editAttendancePackage.name)

  const [loading, setLoading] = useState(false)
  const [redirectToLogin, setRedirectToLogin] = useState(false)

  const mandatoryFields = [
    patientId,
    name,
  ]

  let errorList = [
    patientIdError,
  ]

  if (redirectToLogin || !editAttendancePackage) {
    return <Redirect to="/app"/>
  }

  return (
    <Fragment>
      {loading && <LoadingOverlay/>}

      <Components.CardWithOverlay
        cardType="fullViewer"
        headerClassName=""
        title="Editar Pacote de Atendimentos"
        titleColorClassName=""
        icon={faEdit}
        setOpenCard={setEditAttendancePackage}
        hasConfirmationOnClose={true}
        hasUnsavedChanges={hasUnsavedChanges}
        setHasUnsavedChanges={setHasUnsavedChanges}
        setNotification={setNotification}
      >

        <Components.EditAttendancePackage
          editAttendancePackage={editAttendancePackage}
          setEditAttendancePackage={setEditAttendancePackage}
          getAttendances={editAttendancePackage.getAttendances}
          getAttendancePackages={getAttendancePackages || editAttendancePackage.getAttendancePackages}
          getAndSetEvents={editAttendancePackage.getAndSetEvents || getAndSetEvents || getAttendances}
          hasUnsavedChanges={hasUnsavedChanges}
          setHasUnsavedChanges={setHasUnsavedChanges}
          setNotification={setNotification}
          setLoading={setLoading}
          setRedirectToLogin={setRedirectToLogin}
        />

      </Components.CardWithOverlay>

    </Fragment>
  )
}

export default EditAttendancePackage