import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Dropdown, Icon, Pagination, Button } from "rbx"
import "../../../styles/workspace.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight, faAngleUp } from '@fortawesome/free-solid-svg-icons';

const PaginationController = (props) => {
    
  const { page, setPage, pageSize, setPageSize, totalCount, children } = props

  const paginationRef = useRef()

  let size
  if ((paginationRef.current && paginationRef.current.offsetWidth) < 600) {
    size = "small"
  } else {
    size = undefined
  }

  const pagesCount = totalCount ? Math.ceil(parseInt(totalCount, 10) / parseInt(pageSize, 10)) : 1
  const firstItemPosition = (parseInt(page, 10) * parseInt(pageSize, 10)) - parseInt(pageSize, 10) + 1
  const lastItemPosition = page == pagesCount ? parseInt(totalCount, 10) : firstItemPosition + pageSize - 1

  const paginationList = []

  if (pagesCount <= 5) {
    for (let i = 1; i <= pagesCount; i++) {
      paginationList.push(
        <Pagination.Link key={i} current={page == i} onClick={(e) => {handleLinkClick(e)}}>{i}</Pagination.Link>
      )
    }
  } else {
    if (page <= 3) {
      paginationList.push(
        <Fragment key={"fragment"}>
          <Pagination.Link current={page == 1} onClick={(e) => {handleLinkClick(e)}}>1</Pagination.Link>
          <Pagination.Link current={page == 2} onClick={(e) => {handleLinkClick(e)}}>2</Pagination.Link>
          <Pagination.Link current={page == 3} onClick={(e) => {handleLinkClick(e)}}>3</Pagination.Link>
          {(page == 3) && <Pagination.Link onClick={(e) => {handleLinkClick(e)}}>4</Pagination.Link>}
          <Pagination.Ellipsis/>
          <Pagination.Link onClick={(e) => {handleLinkClick(e)}}>{pagesCount}</Pagination.Link>
        </Fragment>
      )
    } else {
      if (parseInt(page, 10) + 2 < pagesCount) {
        paginationList.push(
          <Fragment key={1}>
            <Pagination.Link onClick={(e) => {handleLinkClick(e)}}>1</Pagination.Link>
            <Pagination.Ellipsis/>
            <Pagination.Link onClick={(e) => {handleLinkClick(e)}}>{parseInt(page, 10) - 1}</Pagination.Link>
            <Pagination.Link current onClick={(e) => {handleLinkClick(e)}}>{page}</Pagination.Link>
            <Pagination.Link onClick={(e) => {handleLinkClick(e)}}>{parseInt(page, 10) + 1}</Pagination.Link>
            <Pagination.Ellipsis/>
            <Pagination.Link onClick={(e) => {handleLinkClick(e)}}>{pagesCount}</Pagination.Link>
          </Fragment>
        )
      } else {
        paginationList.push(
          <Fragment key={1}>
            <Pagination.Link onClick={(e) => {handleLinkClick(e)}}>1</Pagination.Link>
            <Pagination.Ellipsis/>
            {page == pagesCount && <Pagination.Link onClick={(e) => {handleLinkClick(e)}}>{parseInt(page, 10) - 2}</Pagination.Link>}
            <Pagination.Link onClick={(e) => {handleLinkClick(e)}}>{parseInt(page, 10) - 1}</Pagination.Link>
            <Pagination.Link current onClick={(e) => {handleLinkClick(e)}}>{page}</Pagination.Link>
            {(parseInt(page, 10) + 1) <= pagesCount && <Pagination.Link onClick={(e) => {handleLinkClick(e)}}>{parseInt(page, 10) + 1}</Pagination.Link>}
            {(parseInt(page, 10) + 2) <= pagesCount && <Pagination.Link onClick={(e) => {handleLinkClick(e)}}>{parseInt(page, 10) + 2}</Pagination.Link>}
          </Fragment>
        )
      }
    }
  }

  const handleLinkClick = (event) => {
    setPage(event.currentTarget.innerText)
  }

  const handlePreviousClick = () => {
    if (page > 1) setPage(parseInt(page, 10) - 1)
  }

  const handleNextClick = () => {
    if (page < pagesCount) setPage(parseInt(page, 10) + 1)
  }

  return (
    <Fragment>

      {totalCount &&
        <div className="has-text-grey" style={{ marginTop: "auto", fontSize: "0.875rem" }}>
          <span>
            {"Resultados: " + (firstItemPosition && firstItemPosition.toString()) + " - " + (lastItemPosition && lastItemPosition.toString())
            + " de " + (totalCount.toString()) + " | Mostrar "}
          </span>
          <Dropdown up>
            <Dropdown.Trigger>
              <Button size='small' style={{ paddingTop: "0", paddingBottom: "0", height: "1.25rem" }}>
                <span>{pageSize.toString()}</span>
                <Icon size="small">
                  <FontAwesomeIcon icon={faAngleUp} />
                </Icon>
              </Button>
            </Dropdown.Trigger>
            <Dropdown.Menu style={{ minWidth: "1rem" }}>
              <Dropdown.Content>
                <Dropdown.Item active={pageSize == 5} onClick={() => {setPageSize(5); setPage(1)}}>5</Dropdown.Item>
                <Dropdown.Item active={pageSize == 10} onClick={() => {setPageSize(10); setPage(1)}}>10</Dropdown.Item>
                <Dropdown.Item active={pageSize == 25} onClick={() => {setPageSize(25); setPage(1)}}>25</Dropdown.Item>
                <Dropdown.Item active={pageSize == 50} onClick={() => {setPageSize(50); setPage(1)}}>50</Dropdown.Item>
                <Dropdown.Item active={pageSize == 100} onClick={() => {setPageSize(100); setPage(1)}}>100</Dropdown.Item>
              </Dropdown.Content>
            </Dropdown.Menu>
          </Dropdown>
          <span>{" por página"}</span>
        </div>
      }

      {(totalCount > pageSize) &&
        <Pagination ref={paginationRef} rounded size={size} align='centered' className="pt-1">

          <Pagination.Step disabled={page <= 1} align="previous" onClick={handlePreviousClick}>
            <Icon>
              <FontAwesomeIcon icon={faAngleLeft} />
            </Icon>
            {size != "small" &&
              <span>Anterior</span>
            }
          </Pagination.Step>
          <Pagination.Step disabled={page >= pagesCount} align="next" onClick={handleNextClick}>
            {size != "small" &&
              <span>Próximo</span>
            }
            <Icon>
              <FontAwesomeIcon icon={faAngleRight} />
            </Icon>
          </Pagination.Step>
          <Pagination.List>
            {paginationList}
          </Pagination.List>

        </Pagination>
      }

    </Fragment>
)}

export default PaginationController