import React, { lazy, Suspense } from 'react';
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom"

import LoadingOverlay from "../../components/loading-overlay"
import ErrorBoundary from '../../components/workspace/error-boundary'

import Home from './home'
import Constructing from "./constructing"

const Calendar = lazy(() => import('./calendar'))
const Patient = lazy(() => import('./patient'))
const Attendances = lazy(() => import('./attendance'))
const AttendancePackages = lazy(() => import('./attendance-package'))
const DocumentTemplates = lazy(() => import('./document-template'))
const Users = lazy(() => import('./user'))
const EditMyCompany = lazy(() => import('./my-company/edit-my-company'))
const Companies = lazy(() => import('./company'))
const Finances = lazy(() => import('./finance'))
const Report = lazy(() => import('./report'))
const Errors = lazy(() => import('./error'))
const System = lazy(() => import('./system'))
const MyAccount = lazy(() => import('./my-account'))

const Workspace = (props) => {
  const { path } = useRouteMatch()

  const { focusedItem, setFocusedItem, logoImage, hasUnsavedChanges, setHasUnsavedChanges, setNotification } = props

  const user = JSON.parse(localStorage.getItem('user'))

  return (
    <Switch>

      <Route exact path={path}>
        <Redirect push to={{ pathname: `${path}/home` }} />
      </Route>

      <Route path={`${path}/home`}>
        {(user.isSystemAdm && !(user.isCompanyAdm || user.isProfessional || user.isSecretary)) &&
          <ErrorBoundary origin="SystemAdmHome" >
            <Constructing />
          </ErrorBoundary>
          ||
          <ErrorBoundary origin="Home" >
            <Home objectToEdit={props.objectToEdit} setObjectToEdit={props.setObjectToEdit} setFocusedItem={setFocusedItem} hasUnsavedChanges={hasUnsavedChanges} setHasUnsavedChanges={setHasUnsavedChanges} setNotification={setNotification} logoImage={logoImage} />
          </ErrorBoundary>
        }
      </Route>

      <Route path={`${path}/calendar`}>
        <ErrorBoundary origin="Calendar" >
          <Suspense fallback={<LoadingOverlay loaderClass="workspace-loader" />}>
            <Calendar objectToEdit={props.objectToEdit} setObjectToEdit={props.setObjectToEdit} setFocusedItem={setFocusedItem} hasUnsavedChanges={hasUnsavedChanges} setHasUnsavedChanges={setHasUnsavedChanges} setNotification={setNotification} logoImage={logoImage} />
          </Suspense>
        </ErrorBoundary>
      </Route>

      <Route path={`${path}/patient`}>
        <ErrorBoundary origin="Patient" >
          <Suspense fallback={<LoadingOverlay loaderClass="workspace-loader" />}>
            <Patient setObjectToEdit={props.setObjectToEdit} focusedItem={focusedItem} setFocusedItem={setFocusedItem} hasUnsavedChanges={hasUnsavedChanges} setHasUnsavedChanges={setHasUnsavedChanges} setNotification={setNotification} logoImage={logoImage} />
          </Suspense>
        </ErrorBoundary>
      </Route>

      <Route path={`${path}/attendance`}>
        <ErrorBoundary origin="Attendances" >
          <Suspense fallback={<LoadingOverlay loaderClass="workspace-loader" />}>
            <Attendances objectToEdit={props.objectToEdit} setObjectToEdit={props.setObjectToEdit} hasUnsavedChanges={hasUnsavedChanges} setHasUnsavedChanges={setHasUnsavedChanges} setNotification={setNotification} logoImage={logoImage} />
          </Suspense>
        </ErrorBoundary>
      </Route>

      <Route path={`${path}/attendance-package`}>
        <ErrorBoundary origin="AttendancePackages" >
          <Suspense fallback={<LoadingOverlay loaderClass="workspace-loader" />}>
            <AttendancePackages objectToEdit={props.objectToEdit} setObjectToEdit={props.setObjectToEdit} hasUnsavedChanges={hasUnsavedChanges} setHasUnsavedChanges={setHasUnsavedChanges} setNotification={setNotification} />
          </Suspense>
        </ErrorBoundary>
      </Route>

      <Route path={`${path}/document-template`}>
        <ErrorBoundary origin="DocumentTemplates" >
          <Suspense fallback={<LoadingOverlay loaderClass="workspace-loader" />}>
            <DocumentTemplates objectToEdit={props.objectToEdit} setObjectToEdit={props.setObjectToEdit} focusedItem={focusedItem} setFocusedItem={setFocusedItem} hasUnsavedChanges={hasUnsavedChanges} setHasUnsavedChanges={setHasUnsavedChanges} setNotification={setNotification} logoImage={logoImage} />
          </Suspense>
        </ErrorBoundary>
      </Route>

      <Route path={`${path}/finance`}>
        <ErrorBoundary origin="Finances" >
          <Suspense fallback={<LoadingOverlay loaderClass="workspace-loader" />}>
            <Finances objectToEdit={props.objectToEdit} setObjectToEdit={props.setObjectToEdit} setFocusedItem={setFocusedItem} hasUnsavedChanges={hasUnsavedChanges} setHasUnsavedChanges={setHasUnsavedChanges} setNotification={setNotification} logoImage={logoImage} />
          </Suspense>
        </ErrorBoundary>
      </Route>

      <Route path={`${path}/report`}>
        <ErrorBoundary origin="Report" >
          <Suspense fallback={<LoadingOverlay loaderClass="workspace-loader" />}>
            <Report objectToEdit={props.objectToEdit} setObjectToEdit={props.setObjectToEdit} setFocusedItem={setFocusedItem} hasUnsavedChanges={hasUnsavedChanges} setHasUnsavedChanges={setHasUnsavedChanges} setNotification={setNotification} />
          </Suspense>
        </ErrorBoundary>
      </Route>

      <Route path={`${path}/user`}>
        <ErrorBoundary origin="Users" >
          <Suspense fallback={<LoadingOverlay loaderClass="workspace-loader" />}>
            <Users objectToEdit={props.objectToEdit} setObjectToEdit={props.setObjectToEdit} hasUnsavedChanges={hasUnsavedChanges} setHasUnsavedChanges={setHasUnsavedChanges} setNotification={setNotification} />
          </Suspense>
        </ErrorBoundary>
      </Route>

      <Route path={`${path}/company`}>
        <ErrorBoundary origin="EditMyCompany" >
          <Suspense fallback={<LoadingOverlay loaderClass="workspace-loader" />}>
            <EditMyCompany objectToEdit={props.objectToEdit} setObjectToEdit={props.setObjectToEdit} logoImage={props.logoImage} setLogoImage={props.setLogoImage} setTradingName={props.setTradingName} hasUnsavedChanges={hasUnsavedChanges} setHasUnsavedChanges={setHasUnsavedChanges} setNotification={setNotification} />
          </Suspense>
        </ErrorBoundary>
      </Route>

      <Route path={`${path}/companies`}>
        <ErrorBoundary origin="Companies" >
          <Suspense fallback={<LoadingOverlay loaderClass="workspace-loader" />}>
            <Companies objectToEdit={props.objectToEdit} setObjectToEdit={props.setObjectToEdit} hasUnsavedChanges={hasUnsavedChanges} setHasUnsavedChanges={setHasUnsavedChanges} setNotification={setNotification} />
          </Suspense>
        </ErrorBoundary>
      </Route>

      <Route path={`${path}/error`}>
        <ErrorBoundary origin="Errors" >
          <Suspense fallback={<LoadingOverlay loaderClass="workspace-loader" />}>
            <Errors objectToEdit={props.objectToEdit} setObjectToEdit={props.setObjectToEdit} setFocusedItem={setFocusedItem} hasUnsavedChanges={hasUnsavedChanges} setHasUnsavedChanges={setHasUnsavedChanges} setNotification={setNotification} />
          </Suspense>
        </ErrorBoundary>
      </Route>

      <Route path={`${path}/system`}>
        <ErrorBoundary origin="System" >
          <Suspense fallback={<LoadingOverlay loaderClass="workspace-loader" />}>
            <System objectToEdit={props.objectToEdit} setObjectToEdit={props.setObjectToEdit} setFocusedItem={setFocusedItem} hasUnsavedChanges={hasUnsavedChanges} setHasUnsavedChanges={setHasUnsavedChanges} setNotification={setNotification} />
          </Suspense>
        </ErrorBoundary>
      </Route>

      <Route path={`${path}/my-account`}>
        <ErrorBoundary origin="MyAccount" >
          <Suspense fallback={<LoadingOverlay loaderClass="workspace-loader" />}>
            <MyAccount changeHeaderUserName={props.changeHeaderUserName} hasUnsavedChanges={hasUnsavedChanges} setHasUnsavedChanges={setHasUnsavedChanges} setNotification={setNotification} />
          </Suspense>
        </ErrorBoundary>

      </Route>

      <Route>
        <ErrorBoundary origin="Constructing" >
          <Constructing />
        </ErrorBoundary>
      </Route>

    </Switch>
  )
}

export default Workspace;