import Api from "./api"

const ClinicalExamsService = {

  searchClinicalExamsByPatientId: async (params) => {
    let response = await Api.post("/clinicalExams/searchClinicalExamsByPatientId", params,
    {
      headers: {
      "auth-token": localStorage.getItem("auth-token"),
      "keep-logged": localStorage.getItem("keep-logged") 
      }
    })
    localStorage.setItem("auth-token", response.headers["auth-token"])
    return response
  },

  deleteClinicalExam: async (params) => {
    let response = await Api.post("/clinicalExams/deleteClinicalExam", params,
    {
      headers: {
      "auth-token": localStorage.getItem("auth-token"),
      "keep-logged": localStorage.getItem("keep-logged") 
      }
    })
    localStorage.setItem("auth-token", response.headers["auth-token"])
    return response
  },

}

export default ClinicalExamsService