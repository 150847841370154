import React, { Fragment, useState, useEffect } from 'react'
import { Redirect } from "react-router-dom"
import { Column, Icon, Title, Section } from "rbx"
import Components, { methods } from "./methods-and-components/methods-and-components"
import "../../../styles/workspace.scss"
import "../../../styles/loading.scss"
import logoImage from "../../../assets/images/logo-512.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLightbulb } from '@fortawesome/free-regular-svg-icons'

import systemVersion from "../../../config/systemVersion"

const AboutOverlay = (props) => {
  const { setOpenAboutOverlay } = props

  return (
    <Components.CardWithOverlay
      cardType=""
      headerClassName=""
      title="Sobre o sistema"
      titleColorClassName=""
      icon={faLightbulb}
      setOpenCard={setOpenAboutOverlay}
      hasConfirmationOnClose={false}
    >

      <Section>

        <Column.Group centered breakpoint="mobile" textAlign="centered">
          <Column size={5} >
            <img src={logoImage} alt="logo"/>
          </Column>
        </Column.Group>

        <Column.Group>
          <Column size={12} className="pt-0">
            <Title size={2} className="has-text-custom-green-dark has-text-centered">
              CliniWeb
            </Title>
            <Title subtitle size={6} className="has-text-grey has-text-centered">
              Gestão descomplicada
            </Title>
            <Title subtitle size={6} className="has-text-grey has-text-centered">
              {"Versão: " + systemVersion}
            </Title>
            <p className="has-text-centered">
              {"Site: "}
              <a href="https://cliniwebgestao.com.br" className="is-underlined">
                cliniwebgestao.com.br
              </a>
            </p>

          </Column>
        </Column.Group>

      </Section>

    </Components.CardWithOverlay>
  )
}

export default AboutOverlay;