import React, { useState } from 'react'
import ErrorsService from "../../../services/errors"
import LoadingOverlay from "../../components/loading-overlay"
import NotificationOverlay from '../../components/notification-overlay'

const TextComponent = ({ error, info }) => {

  const [showDetails, setShowDetails] = useState(false)

  return (
    <div>
      <p className="mb-3">
        Ocorreu um erro. Tentaremos recarregar a página.
      </p>
      <p className="mb-4 is-underlined">
        <span className="is-clickable" onClick={e => {setShowDetails(!showDetails)}}>
          Ver Detalhes:
        </span>
      </p>
      {showDetails &&
        <div>
          <p className="mb-1">
            Mensagem de erro:
          </p>
          <p className="has-text-weight-normal mb-3">
            {error.toString()}
          </p>
          <p className="mb-1">
            Component Stack:
          </p>
          <p className="has-text-weight-normal">
            {info.componentStack}
          </p>
        </div>
      }
    </div>
  )
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      showDetails: false,
      errorNotification: null
    }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    ErrorsService.newError({
      origin: this.props.origin,
      message: error.toString(),
      stack: info.componentStack
    })
    .then((response) => {
      this.setState({
        errorNotification: {
          success: false,
          text: <TextComponent error={error} info={info} />,
          callback: async () => {
            localStorage.removeItem("user", null)
            localStorage.removeItem("auth-token", null)
            localStorage.removeItem("keepLogged", null)
            window.location.href = "/"
          }
        }
      })
    })
    .catch(function (error) {
      console.log(error)
    })
  }

  render() {
    if (this.state.hasError) {
      if (this.state.errorNotification) {
        return <NotificationOverlay notification={this.state.errorNotification} />
      }
      return <LoadingOverlay/>
    }
    return this.props.children;
  }
}

export default ErrorBoundary