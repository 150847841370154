import React, { Fragment, useState, useEffect } from 'react';
import { Redirect, useHistory } from "react-router-dom";
import { Button, Title, Icon, Dropdown, Level,Label, Column } from "rbx"
import Components, { methods } from "../methods-and-components/methods-and-components"
import LoadingOverlay from "../../loading-overlay"
import PaginationController from '../pagination'
import AttendancesService from "../../../../services/attendances"
import ClinicalExamsService from "../../../../services/clinicalExams"
import DocumentTemplatesService from '../../../../services/documentTemplates'
import ErrorsService from "../../../../services/errors"
import { DateTime } from "luxon"
import "../../../../styles/workspace.scss"
import "../../../../styles/loading.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faCloudArrowUp, faPencil, faFileImport, faAngleDown, faCloudUpload, faAngleUp, faCheck, faEdit } from '@fortawesome/free-solid-svg-icons'
import { faFilePdf } from '@fortawesome/free-regular-svg-icons'
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js'

const EditAttendance = (props) => {

  const { editAttendance, setEditAttendance, setEditAttendancePackage, logoImage, focusedItem, setFocusedItem, searchAttendances, setObjectToEdit, setNotification, hasUnsavedChanges, setHasUnsavedChanges } = props

  const getAttendances = props.getAttendances || (editAttendance && editAttendance.getAttendances)

  const [attendance, setAttendance] = useState(props.editAttendance)

  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [totalCount, setTotalCount] = useState(0)
  const [firstRender, setFirstRender] = useState(true)

  const [activeTab, setActiveTab] = useState("0")

  const [attendanceDay, setAttendanceDay] = useState(new Date(Date.now()))
  const [attendanceDayError, setAttendanceDayError] = useState(null)
  const [attendanceTime, setAttendanceTime] = useState("08:00")
  const [attendanceTimeError, setAttendanceTimeError] = useState(null)
  const [patient, setPatient] = useState("")
  const [patientId, setPatientId] = useState("")
  const [patientIdError, setPatientIdError] = useState(null)
  const [description, setDescription] = useState("")
  const [price, setPrice] = useState("")

  const [anamnesisState, setAnamnesisState] = useState(() => {if (attendance) {return (attendance.anamnesis ? EditorState.createWithContent(convertFromRaw(attendance.anamnesis)) : null)} else {return null}})
  const [questionnaires, setQuestionnaires] = useState(() => {if (attendance) {return (attendance.questionnaires[0] ? attendance.questionnaires : []) } else {return []}})
  const [procedures, setProcedures] = useState(() => {if (attendance) {return (attendance.procedures[0] ? attendance.procedures : [])} else {return []}})
  const [diagnosisState, setDiagnosisState] = useState(() => {if (attendance) {return (attendance.diagnosis ? EditorState.createWithContent(convertFromRaw(attendance.diagnosis)) : null)} else {return null}})
  const [prognosisState, setPrognosisState] = useState(() => {if (attendance) {return (attendance.prognosis ? EditorState.createWithContent(convertFromRaw(attendance.prognosis)) : null)} else {return null}})
  const [therapeuticPlanState, setTherapeuticPlanState] = useState(() => {if (attendance) {return (attendance.therapeuticPlan ? EditorState.createWithContent(convertFromRaw(attendance.therapeuticPlan)) : null)} else {return null}})

  const [newClinicalExams, setNewClinicalExams] = useState([])
  const [clinicalExamsToEdit, setClinicalExamsToEdit] = useState([])

  const [prescriptions, setPrescriptions] = useState(() => {if (attendance) {return (attendance.prescriptions[0] ? attendance.prescriptions.map( (prescription) => (EditorState.createWithContent(convertFromRaw(prescription)))) : [])} else {return []}})
  const [examRequests, setExamRequests] = useState(() => {if (attendance) {return (attendance.examRequests[0] ? attendance.examRequests.map( (examRequest) => (EditorState.createWithContent(convertFromRaw(examRequest)))) : [])} else {return []}})
  const [attests, setAttests] = useState(() => {if (attendance) {return (attendance.attests[0] ? attendance.attests.map( (attest) => (EditorState.createWithContent(convertFromRaw(attest)))) : [])} else {return []}})
  const [declarations, setDeclarations] = useState(() => {if (attendance) {return (attendance.declarations[0] ? attendance.declarations.map( (declaration) => (EditorState.createWithContent(convertFromRaw(declaration)))) : [])} else {return []}})
  const [reports, setReports] = useState(() => {if (attendance) {return (attendance.reports[0] ? attendance.reports.map( (report) => (EditorState.createWithContent(convertFromRaw(report)))) : [])} else {return []}})
  const [consentTerms, setConsentTerms] = useState(() => {if (attendance) {return (attendance.consentTerms[0] ? attendance.consentTerms.map( (consentTerm) => (EditorState.createWithContent(convertFromRaw(consentTerm)))) : [])} else {return []}})

  const anamnesisRef = React.useRef(null)
  const diagnosisRef = React.useRef(null)
  const prognosisRef = React.useRef(null)
  const therapeuticPlanRef = React.useRef(null)

  const [oldDocumentsArray, setOldDocumentsArray] = useState([])
  const [newDocumentsArray, setNewDocumentsArray] = useState([])

  const [documentTemplates, setDocumentTemplates] = useState([])
  const [openSelectDocumentTemplates, setOpenSelectDocumentTemplates] = useState(null)
  const [openSelectDocumentTemplatesIndex, setOpenSelectDocumentTemplatesIndex] = useState(null)

  const [openNewDocumentTemplate, setOpenNewDocumentTemplate] = useState(false)
  const [openEditDocumentTemplate, setOpenEditDocumentTemplate] = useState(false)

  const editAttendanceLoaded = React.useRef(false)

  const [paymentInfoToRegister, setPaymentInfoToRegister] = useState(null)

  const [previousAttendances, setPreviousAttendances] = useState([])

  const [loading, setLoading] = useState(false)
  const [redirectToLogin, setRedirectToLogin] = useState(false)

  const getPreviousAttendances = async () => {
    setLoading(true)
    await AttendancesService.searchAttendances({
      patientId: attendance.patientId,
      page: page,
      pageSize: pageSize,
      attendanceToFilterId: attendance._id
    })
    .then( (response) => {
      setPreviousAttendances(response.data.items)
      setTotalCount(response.data.metadata.totalCount)
      setLoading(false)
    })
    .catch(async error => {
      setLoading(true)
      await ErrorsService.newError({
        origin: "EditAttendance - getPreviousAttendances",
        message: error && error.response && error.response.data || error && error.message || "não especificado",
        stack: error && error.stack || ""
      })
      .then(response => {
        setLoading(false)
        setNotification({
          success: false,
                        text:
                          error && error.response && error.response.data
                          ||
                          <Fragment>
                            <p className="mb-3">Erro ao processar a solicitação.</p>
                            {error && error.message &&
                              <Fragment>
                                <p className="mb-3 has-text-weight-normal">{"Mensagem: " + error.message}</p>
                                <p className="has-text-weight-normal">{"Stack: " + (error && error.stack || "")}</p>
                              </Fragment>
                            }
                          </Fragment>
                          ,
          callback: ( () => {
            setNotification(false)
          })
        })
      })
      .catch(error => {
        setNotification({
          success: false,
          text:
            error && error.response && error.response.data
            ||
            <Fragment>
              <p className="mb-3">Erro ao processar a solicitação.</p>
              {error && error.message &&
                <Fragment>
                  <p className="mb-3 has-text-weight-normal">{"Mensagem: " + error.message}</p>
                  <p className="has-text-weight-normal">{"Stack: " + (error && error.stack || "")}</p>
                </Fragment>
              }
            </Fragment>
            ,
          callback: ( () => {
            setNotification(false)
          })
        })
      })
    })
  }

  useEffect(() => {
    if (activeTab == "2") {
      const timer = setTimeout(() => {
        editAttendanceLoaded.current = true
      }, 500)
      return () => clearTimeout(timer)
    }
    else {
      editAttendanceLoaded.current = false
    }
  }, [activeTab])

  useEffect( () => {
    try {
      setActiveTab("1") // Necessário para atualizar todos os campos abaixo
      setAttendanceDay(attendance.attendanceDateTime ? new Date(attendance.attendanceDateTime) : new Date(Date.now()))
      setAttendanceTime(attendance.attendanceDateTime ? DateTime.fromJSDate(new Date(attendance.attendanceDateTime)).toLocaleString(DateTime.TIME_24_SIMPLE) : DateTime.fromJSDate(new Date (Date.now())).toLocaleString(DateTime.TIME_24_SIMPLE))
      setPatient(attendance.patient || attendance.patientInfo && attendance.patientInfo[0] && attendance.patientInfo[0].name)
      setPatientId(attendance.patientId)
      setDescription(attendance.description)
      setPrice(attendance.price ? methods.numberToPrice(attendance.price) : "")
      const getClinicalExams = async () => {
        await ClinicalExamsService.searchClinicalExamsByPatientId({
          patientId: attendance.patientId
        })
        .then( (response) => {
          setClinicalExamsToEdit(response.data)
          if (!response.data[0]) {
            setNewClinicalExams([])
          }
        })
      }
      getClinicalExams()
    } catch (error) {
      const onError = async () => {
        setLoading(true)
        await ErrorsService.newError({
          origin: "EditAttendance - getClinicalExams",
          message: error && error.response && error.response.data || error && error.message || "não especificado",
          stack: error && error.stack || ""
        })
        .then(response => {
          setLoading(false)
          setNotification({
            success: false,
            text:
              error && error.response && error.response.data
              ||
              <Fragment>
                <p className="mb-3">Erro ao processar a solicitação.</p>
                {error && error.message &&
                  <Fragment>
                    <p className="mb-3 has-text-weight-normal">{"Mensagem: " + error.message}</p>
                    <p className="has-text-weight-normal">{"Stack: " + (error && error.stack || "")}</p>
                  </Fragment>
                }
              </Fragment>
              ,
            callback: ( () => {
              setNotification(false)
            })
          })
        })
        .catch(error => {
          setNotification({
            success: false,
            text:
              error && error.response && error.response.data
              ||
              <Fragment>
                <p className="mb-3">Erro ao processar a solicitação.</p>
                {error && error.message &&
                  <Fragment>
                    <p className="mb-3 has-text-weight-normal">{"Mensagem: " + error.message}</p>
                    <p className="has-text-weight-normal">{"Stack: " + (error && error.stack || "")}</p>
                  </Fragment>
                }
              </Fragment>
              ,
            callback: ( () => {
              setNotification(false)
            })
          })
        })
      }
      onError()
    }
  }, [])

  useEffect( () => {
    const getDocuments = async () => {
      if ((JSON.parse(localStorage.getItem('user'))._id == attendance.professionalId) || JSON.parse(localStorage.getItem('user')).isCompanyAdm || JSON.parse(localStorage.getItem('user')).isSystemAdm) {
        await AttendancesService.getAttendanceDocuments({
          attendanceId: attendance._id
        })
        .then( (response) => {
          setOldDocumentsArray(response.data)
        })
      }
    }
    getDocuments()
  }, [])

  const getDocumentTemplates = async () => {
    if ((JSON.parse(localStorage.getItem('user'))._id == attendance.professionalId) || JSON.parse(localStorage.getItem('user')).isCompanyAdm || JSON.parse(localStorage.getItem('user')).isSystemAdm) {
      setLoading(true)
      await DocumentTemplatesService.searchDocumentTemplates({
        documentType: "TODOS",
        professional: "",
        showSystemTemplates: true,
        showOtherProfessionalTemplates: true,
      })
      .then( (response) => {
        setDocumentTemplates(response.data)
        setLoading(false)
      })
    }
  }

  useEffect( () => {
    getDocumentTemplates()
  }, [])

  useEffect( () => {
    setAttendance(editAttendance)
  }, [paymentInfoToRegister])

  useEffect( () => {
    if (activeTab == "4" && !previousAttendances[0]) {
      getPreviousAttendances()
    }
  }, [activeTab])

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false)
    } else {
      getPreviousAttendances()
    }
  }, [page])

  const mandatoryFields = [
    attendanceDay,
    attendanceTime,
    patientId,
  ]

  let errorList = [
    attendanceDayError,
    attendanceTimeError,
    patientIdError,
  ]

  const handleSubmit = async (event) => {
    const formData = new FormData()
    formData.append("eventId", attendance._id)
    formData.append("patientId", patientId)
    formData.append("status", "Atendido")
    formData.append("description", description)
    formData.append("price", methods.priceToNumber(price))
    formData.append("attendanceDateTime", new Date(`${DateTime.fromJSDate(attendanceDay).toISODate()}T${attendanceTime}`))
    formData.append("anamnesis", anamnesisState ? JSON.stringify(convertToRaw(anamnesisState.getCurrentContent())) : null)
    formData.append("questionnaires", JSON.stringify(questionnaires))
    formData.append("procedures", JSON.stringify(procedures))
    formData.append("diagnosis", diagnosisState ? JSON.stringify(convertToRaw(diagnosisState.getCurrentContent())) : null)
    formData.append("prognosis", prognosisState ? JSON.stringify(convertToRaw(prognosisState.getCurrentContent())) : null)
    formData.append("therapeuticPlan", therapeuticPlanState ? JSON.stringify(convertToRaw(therapeuticPlanState.getCurrentContent())) : null)
    formData.append("newClinicalExams", JSON.stringify(newClinicalExams))
    formData.append("clinicalExamsToEdit", JSON.stringify(clinicalExamsToEdit))
    formData.append("prescriptions", prescriptions[0] ? JSON.stringify(prescriptions.map( (prescriptionState) => (convertToRaw(prescriptionState.getCurrentContent()))))  : JSON.stringify([]))
    formData.append("examRequests", examRequests[0] ? JSON.stringify(examRequests.map( (examRequestState) => (convertToRaw(examRequestState.getCurrentContent()))))  : JSON.stringify([]))
    formData.append("attests", attests[0] ? JSON.stringify(attests.map( (attestState) => (convertToRaw(attestState.getCurrentContent()))))  : JSON.stringify([]))
    formData.append("declarations", declarations[0] ? JSON.stringify(declarations.map( (declarationState) => (convertToRaw(declarationState.getCurrentContent()))))  : JSON.stringify([]))
    formData.append("reports", reports[0] ? JSON.stringify(reports.map( (reportState) => (convertToRaw(reportState.getCurrentContent()))))  : JSON.stringify([]))
    formData.append("consentTerms", consentTerms[0] ? JSON.stringify(consentTerms.map( (consentTermState) => (convertToRaw(consentTermState.getCurrentContent()))))  : JSON.stringify([]))
    newDocumentsArray.map((document) => {
      if (document.file) {
        formData.append("documents", document.file)
      }
    })
    newDocumentsArray.map(document => {
      errorList.push(document.error)
    })
    let submitInfo = {
      event: event,
      setNotification: setNotification,
      setLoading: setLoading,
      setOpenComponent: null,
      mandatoryFields: mandatoryFields,
      errorList: errorList,
      ServiceMethod: AttendancesService.editAttendance,
      params: formData,
      onSuccessText: "As informações do atendimento foram salvas.",
      onErrorText: "Não foi possível salvar as informações. Tente novamente.",
      onSuccessCallback: async () => {
        setLoading(true)
        const getDocuments = async () => {
          await AttendancesService.getAttendanceDocuments({
            attendanceId: attendance._id
          })
          .then( (response) => {
            setOldDocumentsArray(response.data)
          })
        }
        getDocuments()
        setNewDocumentsArray([])
        getAttendances && await getAttendances({ fullRange: true })
        await ClinicalExamsService.searchClinicalExamsByPatientId({
          patientId: attendance.patientId
        })
        .then( (response) => {
          setNewClinicalExams([])
          setClinicalExamsToEdit(response.data)
          setLoading(false)
        })
      },
      onErrorCallback: null,
      setRedirectToLogin: setRedirectToLogin,
      setHasUnsavedChanges: setHasUnsavedChanges,
    }

    methods.handleSubmit(submitInfo)
  }

  if (redirectToLogin || !attendance) {
    return <Redirect to="/app"/>
  }

  return (
    <Fragment>
      {loading && <LoadingOverlay/>}

      <Components.CardWithOverlay
        cardType="fullViewer"
        headerClassName=""
        title={"Atendimento: " + (editAttendance.patient || editAttendance.patientInfo && editAttendance.patientInfo[0] && editAttendance.patientInfo[0].name)}
        titleColorClassName=""
        icon={faEdit}
        setOpenCard={setEditAttendance}
        hasConfirmationOnClose={true}
        hasUnsavedChanges={hasUnsavedChanges}
        setHasUnsavedChanges={setHasUnsavedChanges}
        setNotification={setNotification}
      >

        <Components.AttendanceTabs
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />

          {activeTab == "1" &&
            <Fragment>
              <Components.AttendanceGeneralInfo
                attendance={editAttendance}
                setAttendance={setAttendance}
                attendanceDay={attendanceDay}
                setAttendanceDay={setAttendanceDay}
                attendanceDayError={attendanceDayError}
                setAttendanceDayError={setAttendanceDayError}
                attendanceTime={attendanceTime}
                setAttendanceTime={setAttendanceTime}
                attendanceTimeError={attendanceTimeError}
                setAttendanceTimeError={setAttendanceTimeError}
                patient={patient}
                setPatient={setPatient}
                patientId={patientId}
                setPatientId={setPatientId}
                description={description}
                setDescription={setDescription}
                price={price}
                setPrice={setPrice}
                setPaymentInfoToRegister={setPaymentInfoToRegister}
                setObjectToEdit={setObjectToEdit}
                setEditAttendance={setEditAttendance}
                setEditAttendancePackage={setEditAttendancePackage}
                getAttendances={getAttendances}
                searchAttendances={searchAttendances}
                setFocusedItem={setFocusedItem}
                setHasUnsavedChanges={setHasUnsavedChanges}
                setNotification={setNotification}
                setLoading={setLoading}
                setRedirectToLogin={setRedirectToLogin}
              />
              {paymentInfoToRegister &&
                <Components.RegisterPaymentOverlay
                  paymentInfoToRegister={paymentInfoToRegister}
                  setPaymentInfoToRegister={setPaymentInfoToRegister}
                  setAttendance={setAttendance}
                  setObjectToEdit={setObjectToEdit}
                  setEditAttendance={setEditAttendance}
                  getAttendances={getAttendances}
                  ServiceMethod={AttendancesService.registerAttendancePayment}
                  paymentType="atendimento"
                  setNotification={setNotification}
                  setLoading={setLoading}
                  setRedirectToLogin={setRedirectToLogin}
                />
              }
              <span className="mb-3"></span>
            </Fragment>
          }

          {activeTab == "2" &&
            <Fragment>
              {(JSON.parse(localStorage.getItem('user'))._id == attendance.professionalId) &&
                <Column.Group>

                  <Column narrow className="py-1">
                    <Dropdown>
                      <Dropdown.Trigger>
                        <Button
                          className="has-text-weight-bold"
                        >
                          <Icon align="left" color="info">
                            <FontAwesomeIcon icon={faPencil} />
                          </Icon>
                          <span>Inserir Registro</span>
                          <Icon>
                            <FontAwesomeIcon icon={faAngleDown} />
                          </Icon>
                        </Button>
                      </Dropdown.Trigger>
                      <Dropdown.Menu>
                        <Dropdown.Content>
                          <Dropdown.Item onClick={() => {!anamnesisState       ? setAnamnesisState(EditorState.createEmpty()) : anamnesisRef.current.getClientRects()}}>Anamnese{anamnesisState ? <Icon className={"document-check"}><FontAwesomeIcon icon={faCheck} /></Icon> : ""}</Dropdown.Item>
                          <Dropdown.Item onClick={(e) => {
                            setQuestionnaires((questionnaires) => ([...questionnaires, { name: "Questionário", questions: [{ question: "", answerType: "Subjetiva", answer: "", alternatives: [{ alternative: "Opção 1", chosen: true }, { alternative: "Opção 2", chosen: false }] }] }]))
                          }}>
                            Questionário
                            {questionnaires[0] ? <Icon className={"document-check"}>{questionnaires.length}</Icon> : ""}
                          </Dropdown.Item>
                          <Dropdown.Item onClick={(e) => {setProcedures((procedures) => ([...procedures, { name: "", notes: "", }]))}}>
                            Procedimento
                            {procedures[0] ? <Icon className={"document-check"}>{procedures.length}</Icon> : ""}
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => {!diagnosisState       ? setDiagnosisState(EditorState.createEmpty()) : diagnosisRef.current.getClientRects()}}>Diagnósitco{diagnosisState ? <Icon className={"document-check"}><FontAwesomeIcon icon={faCheck} /></Icon> : ""}</Dropdown.Item>
                          <Dropdown.Item onClick={() => {!prognosisState       ? setPrognosisState(EditorState.createEmpty()) : prognosisRef.current.getClientRects()}}>Prognóstico{prognosisState ? <Icon className={"document-check"}><FontAwesomeIcon icon={faCheck} /></Icon> : ""}</Dropdown.Item>
                          <Dropdown.Item onClick={() => {!therapeuticPlanState ? setTherapeuticPlanState(EditorState.createEmpty()) : therapeuticPlanRef.current.getClientRects()}}>Plano terapêutico{therapeuticPlanState ? <Icon className={"document-check"}><FontAwesomeIcon icon={faCheck} /></Icon> : ""}</Dropdown.Item>
                        </Dropdown.Content>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Column>

                  <Column narrow className="py-1">
                    <Dropdown>
                      <Dropdown.Trigger>
                        <Button
                          className="has-text-weight-bold"
                        >
                          <Icon align="left" color="danger">
                            <FontAwesomeIcon icon={faFilePdf} />
                          </Icon>
                          <span>Emitir Documento</span>
                          <Icon>
                            <FontAwesomeIcon icon={faAngleDown} />
                          </Icon>
                        </Button>
                      </Dropdown.Trigger>
                      <Dropdown.Menu>
                        <Dropdown.Content>
                          <Dropdown.Item onClick={(e) => {setPrescriptions((items) => {return [...items, EditorState.createEmpty()]})}}>Receituário{prescriptions[0] ? <Icon className={"document-check"}>{procedures.length}</Icon> : ""}</Dropdown.Item>
                          <Dropdown.Item onClick={(e) => {setExamRequests ((items) => {return [...items, EditorState.createEmpty()]})}}>Requisição de exames{examRequests[0] ? <Icon className={"document-check"}>{procedures.length}</Icon> : ""}</Dropdown.Item>
                          <Dropdown.Item onClick={(e) => {setAttests      ((items) => {return [...items, EditorState.createEmpty()]})}}>Atestado{attests[0] ? <Icon className={"document-check"}>{procedures.length}</Icon> : ""}</Dropdown.Item>
                          <Dropdown.Item onClick={(e) => {setDeclarations ((items) => {return [...items, EditorState.createEmpty()]})}}>Declaração{declarations[0] ? <Icon className={"document-check"}>{procedures.length}</Icon> : ""}</Dropdown.Item>
                          <Dropdown.Item onClick={(e) => {setReports      ((items) => {return [...items, EditorState.createEmpty()]})}}>Laudo{reports[0] ? <Icon className={"document-check"}>{procedures.length}</Icon> : ""}</Dropdown.Item>
                          <Dropdown.Item onClick={(e) => {setConsentTerms ((items) => {return [...items, EditorState.createEmpty()]})}}>Termo de consentimento{consentTerms[0] ? <Icon className={"document-check"}>{procedures.length}</Icon> : ""}</Dropdown.Item>
                        </Dropdown.Content>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Column>

                  <Column narrow className="py-1">
                    <Button
                      className="has-text-weight-bold"
                      onClick={(e) => {
                        if (oldDocumentsArray.length + newDocumentsArray.length < 10) {
                          setNewDocumentsArray((newDocumentsArray) => {
                            return [...newDocumentsArray, {}]
                          })
                        } else {
                          setNotification({
                            title: "Máximo de arquivos",
                            text: "O número máximo de arquivos é 10.",
                            callback: ( () => {
                              setNotification(false)
                            }),
                          })
                        }
                      }}
                    >
                      <Icon align="left" color="success">
                        <FontAwesomeIcon icon={faFileImport} />
                      </Icon>
                      <span>Anexar Arquivo</span>
                    </Button>
                  </Column>

                  <hr className="card-divider mt-3" />

                </Column.Group>
              }

              {(JSON.parse(localStorage.getItem('user')).isProfessional || (JSON.parse(localStorage.getItem('user')).isCompanyAdm || JSON.parse(localStorage.getItem('user')).isSystemAdm)) &&
                <Fragment>
                  {(anamnesisState &&
                    <Components.RegisterCard
                      title="Anamnese"
                      attendance={attendance}
                      editorState={anamnesisState}
                      setEditorState={setAnamnesisState}
                      documentTemplates={documentTemplates}
                      getDocumentTemplates={getDocumentTemplates}
                      openSelectDocumentTemplates={openSelectDocumentTemplates}
                      setOpenSelectDocumentTemplates={setOpenSelectDocumentTemplates}
                      openSelectDocumentTemplatesIndex={openSelectDocumentTemplatesIndex}
                      setOpenSelectDocumentTemplatesIndex={setOpenSelectDocumentTemplatesIndex}
                      editAttendanceLoaded={editAttendanceLoaded}
                      setOpenEditDocumentTemplate={setOpenEditDocumentTemplate}
                      setOpenNewDocumentTemplate={setOpenNewDocumentTemplate}
                      elementRef={anamnesisRef}
                      setHasUnsavedChanges={setHasUnsavedChanges}
                      setNotification={setNotification}
                      setLoading={setLoading}
                      setRedirectToLogin={setRedirectToLogin}
                    />                                   
                  )}

                  {questionnaires[0] &&
                    <Components.Questionnaires
                      title="Questionário"
                      attendance={attendance}
                      questionnaires={questionnaires}
                      setQuestionnaires={setQuestionnaires}
                      documentTemplates={documentTemplates}
                      getDocumentTemplates={getDocumentTemplates}
                      openSelectDocumentTemplates={openSelectDocumentTemplates}
                      setOpenSelectDocumentTemplates={setOpenSelectDocumentTemplates}
                      openSelectDocumentTemplatesIndex={openSelectDocumentTemplatesIndex}
                      setOpenSelectDocumentTemplatesIndex={setOpenSelectDocumentTemplatesIndex}
                      editAttendanceLoaded={editAttendanceLoaded}
                      setOpenEditDocumentTemplate={setOpenEditDocumentTemplate}
                      setOpenNewDocumentTemplate={setOpenNewDocumentTemplate}
                      setHasUnsavedChanges={setHasUnsavedChanges}
                      setNotification={setNotification}
                      setLoading={setLoading}
                      setRedirectToLogin={setRedirectToLogin}
                    />
                  }

                  {(procedures[0] &&
                    <Components.Procedures
                      title="Procedimento"
                      attendance={attendance}
                      procedures={procedures}
                      setProcedures={setProcedures}
                      documentTemplates={documentTemplates}
                      getDocumentTemplates={getDocumentTemplates}
                      openSelectDocumentTemplates={openSelectDocumentTemplates}
                      setOpenSelectDocumentTemplates={setOpenSelectDocumentTemplates}
                      openSelectDocumentTemplatesIndex={openSelectDocumentTemplatesIndex}
                      setOpenSelectDocumentTemplatesIndex={setOpenSelectDocumentTemplatesIndex}
                      editAttendanceLoaded={editAttendanceLoaded}
                      setOpenEditDocumentTemplate={setOpenEditDocumentTemplate}
                      setOpenNewDocumentTemplate={setOpenNewDocumentTemplate}
                      setHasUnsavedChanges={setHasUnsavedChanges}
                      setNotification={setNotification}
                      setLoading={setLoading}
                      setRedirectToLogin={setRedirectToLogin}
                    />
                  )}

                  {(diagnosisState &&
                    <Components.RegisterCard
                      title="Diagnóstico"
                      attendance={attendance}
                      editorState={diagnosisState}
                      setEditorState={setDiagnosisState}
                      documentTemplates={documentTemplates}
                      getDocumentTemplates={getDocumentTemplates}
                      openSelectDocumentTemplates={openSelectDocumentTemplates}
                      setOpenSelectDocumentTemplates={setOpenSelectDocumentTemplates}
                      openSelectDocumentTemplatesIndex={openSelectDocumentTemplatesIndex}
                      setOpenSelectDocumentTemplatesIndex={setOpenSelectDocumentTemplatesIndex}
                      editAttendanceLoaded={editAttendanceLoaded}
                      setOpenEditDocumentTemplate={setOpenEditDocumentTemplate}
                      setOpenNewDocumentTemplate={setOpenNewDocumentTemplate}
                      elementRef={diagnosisRef}
                      setHasUnsavedChanges={setHasUnsavedChanges}
                      setNotification={setNotification}
                      setLoading={setLoading}
                      setRedirectToLogin={setRedirectToLogin}
                    />
                  )}

                  {(prognosisState &&
                    <Components.RegisterCard
                      title="Prognóstico"
                      attendance={attendance}
                      editorState={prognosisState}
                      setEditorState={setPrognosisState}
                      documentTemplates={documentTemplates}
                      getDocumentTemplates={getDocumentTemplates}
                      openSelectDocumentTemplates={openSelectDocumentTemplates}
                      setOpenSelectDocumentTemplates={setOpenSelectDocumentTemplates}
                      openSelectDocumentTemplatesIndex={openSelectDocumentTemplatesIndex}
                      setOpenSelectDocumentTemplatesIndex={setOpenSelectDocumentTemplatesIndex}
                      editAttendanceLoaded={editAttendanceLoaded}
                      setOpenEditDocumentTemplate={setOpenEditDocumentTemplate}
                      setOpenNewDocumentTemplate={setOpenNewDocumentTemplate}
                      elementRef={prognosisRef}
                      setHasUnsavedChanges={setHasUnsavedChanges}
                      setNotification={setNotification}
                      setLoading={setLoading}
                      setRedirectToLogin={setRedirectToLogin}
                    />
                  )}

                  {(therapeuticPlanState &&
                    <Components.RegisterCard
                      title="Plano Terapêutico"
                      attendance={attendance}
                      editorState={therapeuticPlanState}
                      setEditorState={setTherapeuticPlanState}
                      documentTemplates={documentTemplates}
                      getDocumentTemplates={getDocumentTemplates}
                      openSelectDocumentTemplates={openSelectDocumentTemplates}
                      setOpenSelectDocumentTemplates={setOpenSelectDocumentTemplates}
                      openSelectDocumentTemplatesIndex={openSelectDocumentTemplatesIndex}
                      setOpenSelectDocumentTemplatesIndex={setOpenSelectDocumentTemplatesIndex}
                      editAttendanceLoaded={editAttendanceLoaded}
                      setOpenEditDocumentTemplate={setOpenEditDocumentTemplate}
                      setOpenNewDocumentTemplate={setOpenNewDocumentTemplate}
                      elementRef={therapeuticPlanRef}
                      setHasUnsavedChanges={setHasUnsavedChanges}
                      setNotification={setNotification}
                      setLoading={setLoading}
                      setRedirectToLogin={setRedirectToLogin}
                    />
                  )}
                </Fragment>
              }

              {prescriptions[0] &&
                <Components.PdfEditors
                  editorTitle="Receituário"
                  editorStatesArray={prescriptions}
                  setEditorStatesArray={setPrescriptions}
                  logoImage={logoImage}
                  patient={patient}
                  attendance={attendance}
                  documentTemplates={documentTemplates}
                  getDocumentTemplates={getDocumentTemplates}
                  openSelectDocumentTemplates={openSelectDocumentTemplates}
                  setOpenSelectDocumentTemplates={setOpenSelectDocumentTemplates}
                  openSelectDocumentTemplatesIndex={openSelectDocumentTemplatesIndex}
                  setOpenSelectDocumentTemplatesIndex={setOpenSelectDocumentTemplatesIndex}
                  editAttendanceLoaded={editAttendanceLoaded}
                  setOpenEditDocumentTemplate={setOpenEditDocumentTemplate}
                  setOpenNewDocumentTemplate={setOpenNewDocumentTemplate}
                  setHasUnsavedChanges={setHasUnsavedChanges}
                  setNotification={setNotification}
                  setLoading={setLoading}
                  setRedirectToLogin={setRedirectToLogin}
                />
              }

              {examRequests[0] &&
                <Components.PdfEditors
                  editorTitle="Requisição de exames"
                  editorStatesArray={examRequests}
                  setEditorStatesArray={setExamRequests}
                  logoImage={logoImage}
                  patient={patient}
                  attendance={attendance}
                  documentTemplates={documentTemplates}
                  getDocumentTemplates={getDocumentTemplates}
                  openSelectDocumentTemplates={openSelectDocumentTemplates}
                  setOpenSelectDocumentTemplates={setOpenSelectDocumentTemplates}
                  openSelectDocumentTemplatesIndex={openSelectDocumentTemplatesIndex}
                  setOpenSelectDocumentTemplatesIndex={setOpenSelectDocumentTemplatesIndex}
                  editAttendanceLoaded={editAttendanceLoaded}
                  setOpenEditDocumentTemplate={setOpenEditDocumentTemplate}
                  setOpenNewDocumentTemplate={setOpenNewDocumentTemplate}
                  setHasUnsavedChanges={setHasUnsavedChanges}
                  setNotification={setNotification}
                  setLoading={setLoading}
                  setRedirectToLogin={setRedirectToLogin}
                />
              }

              {attests[0] &&
                <Components.PdfEditors
                  editorTitle="Atestado"
                  editorStatesArray={attests}
                  setEditorStatesArray={setAttests}
                  logoImage={logoImage}
                  patient={patient}
                  attendance={attendance}
                  documentTemplates={documentTemplates}
                  getDocumentTemplates={getDocumentTemplates}
                  openSelectDocumentTemplates={openSelectDocumentTemplates}
                  setOpenSelectDocumentTemplates={setOpenSelectDocumentTemplates}
                  openSelectDocumentTemplatesIndex={openSelectDocumentTemplatesIndex}
                  setOpenSelectDocumentTemplatesIndex={setOpenSelectDocumentTemplatesIndex}
                  editAttendanceLoaded={editAttendanceLoaded}
                  setOpenEditDocumentTemplate={setOpenEditDocumentTemplate}
                  setOpenNewDocumentTemplate={setOpenNewDocumentTemplate}
                  setHasUnsavedChanges={setHasUnsavedChanges}
                  setNotification={setNotification}
                  setLoading={setLoading}
                  setRedirectToLogin={setRedirectToLogin}
                />
              }

              {declarations[0] &&
                <Components.PdfEditors
                  editorTitle="Declaração"
                  editorStatesArray={declarations}
                  setEditorStatesArray={setDeclarations}
                  logoImage={logoImage}
                  patient={patient}
                  attendance={attendance}
                  documentTemplates={documentTemplates}
                  getDocumentTemplates={getDocumentTemplates}
                  openSelectDocumentTemplates={openSelectDocumentTemplates}
                  setOpenSelectDocumentTemplates={setOpenSelectDocumentTemplates}
                  openSelectDocumentTemplatesIndex={openSelectDocumentTemplatesIndex}
                  setOpenSelectDocumentTemplatesIndex={setOpenSelectDocumentTemplatesIndex}
                  editAttendanceLoaded={editAttendanceLoaded}
                  setOpenEditDocumentTemplate={setOpenEditDocumentTemplate}
                  setOpenNewDocumentTemplate={setOpenNewDocumentTemplate}
                  setHasUnsavedChanges={setHasUnsavedChanges}
                  setNotification={setNotification}
                  setLoading={setLoading}
                  setRedirectToLogin={setRedirectToLogin}
                />
              }

              {reports[0] &&
                <Components.PdfEditors
                  editorTitle="Laudo"
                  editorStatesArray={reports}
                  setEditorStatesArray={setReports}
                  logoImage={logoImage}
                  patient={patient}
                  attendance={attendance}
                  documentTemplates={documentTemplates}
                  getDocumentTemplates={getDocumentTemplates}
                  openSelectDocumentTemplates={openSelectDocumentTemplates}
                  setOpenSelectDocumentTemplates={setOpenSelectDocumentTemplates}
                  openSelectDocumentTemplatesIndex={openSelectDocumentTemplatesIndex}
                  setOpenSelectDocumentTemplatesIndex={setOpenSelectDocumentTemplatesIndex}
                  editAttendanceLoaded={editAttendanceLoaded}
                  setOpenEditDocumentTemplate={setOpenEditDocumentTemplate}
                  setOpenNewDocumentTemplate={setOpenNewDocumentTemplate}
                  setHasUnsavedChanges={setHasUnsavedChanges}
                  setNotification={setNotification}
                  setLoading={setLoading}
                  setRedirectToLogin={setRedirectToLogin}
                />
              }

              {consentTerms[0] &&
                <Components.PdfEditors
                  editorTitle="Termo de consentimento"
                  editorStatesArray={consentTerms}
                  setEditorStatesArray={setConsentTerms}
                  logoImage={logoImage}
                  patient={patient}
                  attendance={attendance}
                  documentTemplates={documentTemplates}
                  getDocumentTemplates={getDocumentTemplates}
                  openSelectDocumentTemplates={openSelectDocumentTemplates}
                  setOpenSelectDocumentTemplates={setOpenSelectDocumentTemplates}
                  openSelectDocumentTemplatesIndex={openSelectDocumentTemplatesIndex}
                  setOpenSelectDocumentTemplatesIndex={setOpenSelectDocumentTemplatesIndex}
                  editAttendanceLoaded={editAttendanceLoaded}
                  setOpenEditDocumentTemplate={setOpenEditDocumentTemplate}
                  setOpenNewDocumentTemplate={setOpenNewDocumentTemplate}
                  setHasUnsavedChanges={setHasUnsavedChanges}
                  setNotification={setNotification}
                  setLoading={setLoading}
                  setRedirectToLogin={setRedirectToLogin}
                />
              }

              {openEditDocumentTemplate &&
                <Components.EditDocumentTemplateOverlay
                  openEditDocumentTemplate={openEditDocumentTemplate}
                  setOpenEditDocumentTemplate={setOpenEditDocumentTemplate}
                  getDocumentTemplates={getDocumentTemplates}
                  setNotification={setNotification}
                  setLoading={setLoading}
                  setRedirectToLogin={setRedirectToLogin}
                />
              }

              {openNewDocumentTemplate &&
                <Components.NewDocumentTemplateOverlay
                  openNewDocumentTemplate={openNewDocumentTemplate}
                  setOpenNewDocumentTemplate={setOpenNewDocumentTemplate}
                  getDocumentTemplates={getDocumentTemplates}
                  setNotification={setNotification}
                  setLoading={setLoading}
                  setRedirectToLogin={setRedirectToLogin}
                />
              }

              {(oldDocumentsArray[0] || newDocumentsArray[0]) &&
                <Components.DocumentsCard>
                  {oldDocumentsArray[0] &&
                    <Components.DocumentPreviewContainersArray
                      oldDocumentsArray={oldDocumentsArray}
                      setOldDocumentsArray={setOldDocumentsArray}
                      attendance={attendance}
                      setNotification={setNotification}
                      setLoading={setLoading}
                      setRedirectToLogin={setRedirectToLogin}
                    />
                    ||
                    <span></span>
                  }

                  {newDocumentsArray[0] &&
                    <Components.DocumentUploadsArray
                      newDocumentsArray={newDocumentsArray}
                      setNewDocumentsArray={setNewDocumentsArray}
                      fileMaxSize={10*1024*1024} /* 10MB */
                      fileType={"de arquivo conhecido"}
                      fileTypeRegEx={/[\s\S]*/}
                      editAttendanceLoaded={editAttendanceLoaded}
                      setNotification={setNotification}
                    />
                  }
                </Components.DocumentsCard>
              }

              <hr className="card-divider mt-3" />

              {((anamnesisState || questionnaires[0] || procedures[0] || diagnosisState || prognosisState || therapeuticPlanState || prescriptions[0] || examRequests[0] || attests[0] || declarations[0] || reports[0] || consentTerms[0] || oldDocumentsArray[0] || newDocumentsArray[0]) &&
                (JSON.parse(localStorage.getItem('user'))._id == attendance.professionalId)) &&
                <Column.Group>

                  <Column narrow className="py-1">
                    <Dropdown up>
                      <Dropdown.Trigger>
                        <Button
                          className="has-text-weight-bold"
                        >
                          <Icon align="left" color="info">
                            <FontAwesomeIcon icon={faPencil} />
                          </Icon>
                          <span>Inserir Registro</span>
                          <Icon>
                            <FontAwesomeIcon icon={faAngleUp} />
                          </Icon>
                        </Button>
                      </Dropdown.Trigger>
                      <Dropdown.Menu>
                        <Dropdown.Content>
                          <Dropdown.Item onClick={() => {!anamnesisState       ? setAnamnesisState(EditorState.createEmpty()) : anamnesisRef.current.getClientRects()}}>Anamnese{anamnesisState ? <Icon className={"document-check"}><FontAwesomeIcon icon={faCheck} /></Icon> : ""}</Dropdown.Item>
                          <Dropdown.Item onClick={(e) => {
                            setQuestionnaires((questionnaires) => ([...questionnaires, { name: "Questionário", questions: [{ question: "", answerType: "Subjetiva", answer: "", alternatives: [{ alternative: "Opção 1", chosen: true }, { alternative: "Opção 2", chosen: false }] }] }]))
                          }}>
                            Questionário
                            {questionnaires[0] ? <Icon className={"document-check"}>{questionnaires.length}</Icon> : ""}
                          </Dropdown.Item>
                          <Dropdown.Item onClick={(e) => {setProcedures((procedures) => ([...procedures, { name: "", notes: "", }]))}}>
                            Procedimento
                            {procedures[0] ? <Icon className={"document-check"}>{procedures.length}</Icon> : ""}
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => {!diagnosisState       ? setDiagnosisState(EditorState.createEmpty()) : diagnosisRef.current.getClientRects()}}>Diagnósitco{diagnosisState ? <Icon className={"document-check"}><FontAwesomeIcon icon={faCheck} /></Icon> : ""}</Dropdown.Item>
                          <Dropdown.Item onClick={() => {!prognosisState       ? setPrognosisState(EditorState.createEmpty()) : prognosisRef.current.getClientRects()}}>Prognóstico{prognosisState ? <Icon className={"document-check"}><FontAwesomeIcon icon={faCheck} /></Icon> : ""}</Dropdown.Item>
                          <Dropdown.Item onClick={() => {!therapeuticPlanState ? setTherapeuticPlanState(EditorState.createEmpty()) : therapeuticPlanRef.current.getClientRects()}}>Plano terapêutico{therapeuticPlanState ? <Icon className={"document-check"}><FontAwesomeIcon icon={faCheck} /></Icon> : ""}</Dropdown.Item>
                        </Dropdown.Content>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Column>

                  <Column narrow className="py-1">
                    <Dropdown up>
                      <Dropdown.Trigger>
                        <Button
                          className="has-text-weight-bold"
                        >
                          <Icon align="left" color="danger">
                            <FontAwesomeIcon icon={faFilePdf} />
                          </Icon>
                          <span>Emitir Documento</span>
                          <Icon>
                            <FontAwesomeIcon icon={faAngleUp} />
                          </Icon>
                        </Button>
                      </Dropdown.Trigger>
                      <Dropdown.Menu>
                        <Dropdown.Content>
                          <Dropdown.Item onClick={(e) => {setPrescriptions((items) => {return [...items, EditorState.createEmpty()]})}}>Receituário{prescriptions[0] ? <Icon className={"document-check"}>{prescriptions.length}</Icon> : ""}</Dropdown.Item>
                          <Dropdown.Item onClick={(e) => {setExamRequests ((items) => {return [...items, EditorState.createEmpty()]})}}>Requisição de exames{examRequests[0] ? <Icon className={"document-check"}>{examRequests.length}</Icon> : ""}</Dropdown.Item>
                          <Dropdown.Item onClick={(e) => {setAttests      ((items) => {return [...items, EditorState.createEmpty()]})}}>Atestado{attests[0] ? <Icon className={"document-check"}>{attests.length}</Icon> : ""}</Dropdown.Item>
                          <Dropdown.Item onClick={(e) => {setDeclarations ((items) => {return [...items, EditorState.createEmpty()]})}}>Declaração{declarations[0] ? <Icon className={"document-check"}>{declarations.length}</Icon> : ""}</Dropdown.Item>
                          <Dropdown.Item onClick={(e) => {setReports      ((items) => {return [...items, EditorState.createEmpty()]})}}>Laudo{reports[0] ? <Icon className={"document-check"}>{reports.length}</Icon> : ""}</Dropdown.Item>
                          <Dropdown.Item onClick={(e) => {setConsentTerms ((items) => {return [...items, EditorState.createEmpty()]})}}>Termo de consentimento{consentTerms[0] ? <Icon className={"document-check"}>{consentTerms.length}</Icon> : ""}</Dropdown.Item>
                        </Dropdown.Content>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Column>

                  <Column narrow className="py-1">
                    <Button
                      className="has-text-weight-bold"
                      onClick={(e) => {
                        if (oldDocumentsArray.length + newDocumentsArray.length < 10) {
                          setNewDocumentsArray((newDocumentsArray) => {
                            return [...newDocumentsArray, {}]
                          })
                        } else {
                          setNotification({
                            title: "Máximo de arquivos",
                            text: "O número máximo de arquivos é 10.",
                            callback: ( () => {
                              setNotification(false)
                            }),
                          })
                        }
                      }}
                    >
                      <Icon align="left" color="success">
                        <FontAwesomeIcon icon={faFileImport} />
                      </Icon>
                      <span>Anexar Arquivo</span>
                      {oldDocumentsArray[0] || newDocumentsArray[0] ? <Icon className={"document-check"}>{oldDocumentsArray.length + newDocumentsArray.length}</Icon> : ""}
                    </Button>
                  </Column>

                </Column.Group>
              }

              {!(anamnesisState || questionnaires[0] || procedures[0] || diagnosisState || prognosisState || therapeuticPlanState || prescriptions[0] || examRequests[0] || attests[0] || declarations[0] || reports[0] || consentTerms[0] || oldDocumentsArray[0] || newDocumentsArray[0]) &&
                <div style={{ height: "150px" }}></div>
              }

            </Fragment>
          }

          {activeTab == "3" &&
            <Fragment>              
              <Fragment>
                {clinicalExamsToEdit[0] &&
                  <Components.ClinicalExams
                    attendance={attendance}
                    clinicalExams={clinicalExamsToEdit}
                    setClinicalExams={setClinicalExamsToEdit}
                    setHasUnsavedChanges={setHasUnsavedChanges}
                    setNotification={setNotification}
                    setLoading={setLoading}
                    setRedirectToLogin={setRedirectToLogin}
                  />
                }
                {newClinicalExams[0] &&
                  <Components.ClinicalExams
                    attendance={attendance}
                    clinicalExams={newClinicalExams}
                    setClinicalExams={setNewClinicalExams}
                    setHasUnsavedChanges={setHasUnsavedChanges}
                    setNotification={setNotification}
                    setLoading={setLoading}
                    setRedirectToLogin={setRedirectToLogin}
                  />
                }
                {(JSON.parse(localStorage.getItem('user'))._id == attendance.professionalId) &&
                  <Level breakpoint="mobile" >
                    <Level.Item>
                      <Button
                        color="success"
                        className="has-text-weight-bold"
                        onClick={(e) => {
                          setNewClinicalExams((newClinicalExams) => (
                            [
                              ...newClinicalExams,
                              {
                                parameter: "",
                                quantityType: "Qualidade",
                                unit: "",
                                measurements: [{
                                  date: new Date(Date.now()),
                                  value: "Regular",
                                  notes: "",
                                }],
                              }
                            ]
                          ))
                        }}
                      >
                        <Icon align="left">
                          <FontAwesomeIcon icon={faPlus} />
                        </Icon>
                        <span>Nova Evolução Clínica</span>
                      </Button>
                    </Level.Item>
                  </Level>
                }
              </Fragment>
            </Fragment>
          }

          {activeTab == "4" &&
            <Fragment>
              {(previousAttendances && previousAttendances[0]) &&
                <Fragment>
                  {previousAttendances.map((previousAttendance, index) => (
                    <Components.PreviousAttendanceCard
                      key={index}
                      attendance={previousAttendance}
                      setEditAttendance={setEditAttendance}
                      setEditAttendancePackage={setEditAttendancePackage}
                      getPreviousAttendances={getPreviousAttendances}
                      hasUnsavedChanges={hasUnsavedChanges}
                      setHasUnsavedChanges={setHasUnsavedChanges}
                      setNotification={setNotification}
                      setLoading={setLoading}
                      setRedirectToLogin={setRedirectToLogin}
                    />
                  ))}
                  <PaginationController
                    page={page}
                    setPage={setPage}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    totalCount={totalCount}
                  />
                </Fragment>
                ||
                <Title size={5} className="has-text-centered has-text-info" >
                  Não há atendimentos anteriores para este paciente.
                </Title>
              }
            </Fragment>
          }

        {(JSON.parse(localStorage.getItem('user'))._id == attendance.professionalId) &&
          <Button.Group align="centered">
            <Button
              color="info"
              size="medium"
              className="has-text-weight-bold"
              onClick={e => {
                if(page != 1) setPage(1)
                else handleSubmit()
              }} >
              <Icon size="small" align="left">
                <FontAwesomeIcon icon={faCloudArrowUp} />
              </Icon>
              <span>Salvar Atendimento</span>
            </Button>
          </Button.Group>
        }

      </Components.CardWithOverlay>

    </Fragment>
  )
}

export default EditAttendance;