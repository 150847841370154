import React, { Fragment, useState, useEffect } from 'react'
import { useHistory, useLocation } from "react-router-dom"
import { Column, Title, Menu, Dropdown } from "rbx"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LoadingAnimation from "./loading-animation"
import "../../styles/sidebar-menu.scss"
import { menuItems } from "../../config/sidebarMenu"

const SidebarMenu = (props) => {
  const [workspaceView, setWorkspaceView] = useState(null);

  const focusedItem = props.focusedItem
  const setFocusedItem = props.setFocusedItem
  const hasUnsavedChanges = props.hasUnsavedChanges
  const setHasUnsavedChanges = props.setHasUnsavedChanges
  const setNotification = props.setNotification

  let history = useHistory()
  let location = useLocation()

  useEffect(() => {
    if (location.pathname != workspaceView) {
      setWorkspaceView(location.pathname)
    }
  })

  useEffect(() => {
    setFocusedItem(location.pathname.slice(5))
  }, [])

  const onItemSelect = (view) => {
    if (hasUnsavedChanges) {
      setNotification({
        withCancel: true,
        success: false,
        title: "Alterações não salvas",
        text: "Pode haver alterações não salvas. Tem certeza que deseja sair?",
        cancelCallback: ( () => {
          setNotification(false)
        }),
        confirmCallback: ( () => {
          setNotification(false)
          setHasUnsavedChanges(false)
          if(/\/app\/edit*/.test(location.pathname)){
            history.replace(`/app/${view}`)
          } else {
            history.push(`/app/${view}`)
          }
          setFocusedItem(view)
        })
      })
    } else {
      if (/\/app\/edit*/.test(location.pathname)) {
        history.replace(`/app/${view}`)
      } else {
        history.push(`/app/${view}`)
      }
      setFocusedItem(view)
    }
  }

return (
  <Menu>
    <div className="logo-section-container">
      <Dropdown.Item as="div">
        <Column.Group vcentered className="logo-section m-0 p-3 has-text-centered"
          onClick={() => {history.push("/app/company");setFocusedItem("company")}}
        >
          <Column className="is-mobile p-0">
            {!props.logoImage &&
              <LoadingAnimation loaderClass="image-loader" />
            }
            <div className="has-text-centered">
              {props.logoImage && <img className="logo-image" src={props.logoImage} alt="logo"/>}
            </div>
            <div>
              <Title size={5}>
                {props.tradingName}
              </Title>
            </div>
          </Column>
        </Column.Group>
      </Dropdown.Item>

    </div>

    <Menu.List>
      {menuItems.map((item, key) => {
        const user = JSON.parse(localStorage.getItem("user"))

        if (!((item.permissions.systemAdm && user.isSystemAdm) || (item.permissions.companyAdm && user.isCompanyAdm) || (item.permissions.professional && user.isProfessional) || (item.permissions.secretary && user.isSecretary))) {
          return null
        }

        return (
          <Fragment key={key}>
            <Menu.List.Item
              className={`menu-label ${focusedItem == item.view ? "is-focused" : ""}`}
              onClick={() => onItemSelect(item.view)}
            >
              <Dropdown.Item as="div" className={`sidebar-menu-item ${focusedItem == item.view ? "is-focused" : ""} p-0 m-0`}>
                <Column.Group className="is-mobile m-0" >
                  <Column size="2" className={`has-text-centered py-0 ${focusedItem == item.view ? "" : "has-text-link-dark"}`} >
                    <FontAwesomeIcon icon={item.icon} color={item.color} />
                  </Column>
                  <Column size="10" className="p-0">
                    <span>{item.label}</span>
                  </Column>
                </Column.Group>
              </Dropdown.Item>
            </Menu.List.Item>
            <hr className="menu-label-divider" /> 
          </Fragment>
        )
      })}
    </Menu.List>
  </Menu>
)}

export default SidebarMenu;