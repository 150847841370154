import Api from "./api"

const ErrorsService = {

  newError: async (params) => {
    const response = await Api.post("/errors/newError", params,
      {
        headers: {
          "auth-token": localStorage.getItem("auth-token"),
          "keep-logged": localStorage.getItem("keep-logged"),
        }
      }
    )
    localStorage.setItem("auth-token", response.headers["auth-token"])
  },

  searchErrors: async (params) => {
    let response = await Api.post("/errors/searchErrors", params,
    {
      headers: {
      "auth-token": localStorage.getItem("auth-token"),
      "keep-logged": localStorage.getItem("keep-logged") 
      }
    })
    localStorage.setItem("auth-token", response.headers["auth-token"])
    return response
  },

  editError: async (params) => {
    let response = await Api.post("/errors/editError", params,
    {
      headers: {
      "auth-token": localStorage.getItem("auth-token"),
      "keep-logged": localStorage.getItem("keep-logged") 
      }
    })
    localStorage.setItem("auth-token", response.headers["auth-token"])
    return response
  },

}

export default ErrorsService